import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './containers/Auth';
import MyLists from './containers/MyLists';
import { AppContextProvider } from './store/AppContext';
import { AuthContextProvider } from './store/AuthContext';
import ErrorPage from './containers/404';
import Instruction from './containers/Instruction';
import ListCreation from './containers/ListCreation';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';
import useRedirectToHttps from './hooks/useRedirectToHttps';

const App = () => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_API_TOKEN,
        scope: ''
      });
    }
    gapi.load('client:auth2', start);
  });

  const ProtectedRoute = ({ children }: any) => {
    if (!localStorage.getItem('refreshToken')) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const AuthRoute = ({ children }: any) => {
    if (
      localStorage.getItem('refreshToken') &&
      window.location.pathname === '/'
    ) {
      return <Navigate to="/my-lists" replace />;
    }
    return children;
  };

  useRedirectToHttps();

  return (
    <>
      <BrowserRouter>
        <AuthContextProvider>
          <AppContextProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Auth />
                  </AuthRoute>
                }
              />
              <Route
                path="/my-lists"
                element={
                  <ProtectedRoute>
                    <MyLists />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/list-creation"
                element={
                  <ProtectedRoute>
                    <ListCreation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/instruction"
                element={
                  <ProtectedRoute>
                    <Instruction />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </AppContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
