import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import { useAppContext } from '../../store/AppContext';
import QuestionIcon from '../../assets/icons/question-mark-circle.svg';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useTranslation } from 'react-i18next';

const SettingsForm = () => {
  const { t } = useTranslation();
  const {
    setShowSettingsForm,
    utmParams,
    setUtmParams,
    setShowDeleteListModal
  } = useAppContext();
  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 100).toString();

  const modalCloseHandler = () => {
    setShowSettingsForm(false);
  };

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {
      editCampaign: utmParams.campaign,
      editSource: utmParams.source,
      editMedium: utmParams.medium
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      editCampaign: Yup.string(),
      editSource: Yup.string(),
      editMedium: Yup.string()
    }),
    onSubmit: () => {
      let changedValues = {
        campaign: values.editCampaign,
        source: values.editSource,
        medium: values.editMedium
      };
      setUtmParams(changedValues);
      setShowSettingsForm(false);
    }
  });

  const utmContent = [
    {
      id: 'editCampaign',
      label: t('utm_campaign'),
      errorMessage: t('errorMessageCampaign'),
      tooltip_h1: t('utm_campaign'),
      tooltip_p: t('utm_campaign_info'),
      value: values.editCampaign
    },
    {
      id: 'editSource',
      label: t('utm_source'),
      errorMessage: t('errorMessageSource'),
      tooltip_h1: t('utm_source'),
      tooltip_p: t('utm_source_info'),
      value: values.editSource
    },
    {
      id: 'editMedium',
      label: t('utm_medium'),
      errorMessage: t('errorMessageMedium'),
      tooltip_h1: t('utm_medium'),
      tooltip_p: t('utm_medium_info'),
      value: values.editMedium
    }
  ];

  return (
    <>
      <Modal
        marginTop={`${margin}px`}
        width="w-80 sm:w-120"
        headingText={t('all_settings')}
        modalClose={modalCloseHandler}
        borderWrapper
      >
        <div className="flex flex-col justify-center gap-3 px-6 py-6">
          {utmContent.map((item) => (
            <div key={item.id}>
              <Input
                id={item.id}
                type="text"
                label={item.label}
                textColor="text-gray-900"
                width="w-full"
                height="h-12"
                size="text-base"
                fontWeight="font-medium"
                errorMessage={item.errorMessage}
                icon={QuestionIcon}
                iconPosition="absolute right-4 top-[41px] z-30"
                tooltip={true}
                tooltip_h1={item.tooltip_h1}
                tooltip_p={item.tooltip_p}
                value={item.value}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between gap-3 px-6 py-6 border-t">
          <Button
            type="submit"
            size="sm"
            bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
            textColor="text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            borderRadius="rounded-lg"
            onClickHandler={handleSubmit}
          >
            {t('save_changes')}
          </Button>
          <Button
            type="submit"
            size="sm"
            bgColor="bg-transparent focus:ring-red-300 hover:bg-red-600 focus:ring-4"
            textColor="text-red-600 hover:text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            border="border border-red-600"
            borderRadius="rounded-lg"
            onClickHandler={() => setShowDeleteListModal(true)}
          >
            {t('delete_list')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SettingsForm;
