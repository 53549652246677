import { useTranslation, Trans } from 'react-i18next';
import ListEditingImg from '../../assets/images/Table.svg';

const ListEditingSection = () => {
  const { t } = useTranslation();
  return (
    <section id="list-editing" className="flex flex-col mt-16">
      <h2 className="text-2xl font-bold text-gray-900">
        {t('list_editing_all')}
      </h2>

      <div className="my-6 text-base text-gray-500">
        <Trans i18nKey="list_editing_instruction" />
        <p className="mt-5">{t('list_editing_tip')}</p>
      </div>
      <img src={ListEditingImg} alt="List editing view" />

      <p className="w-full p-6 mt-6 text-base text-center text-gray-500 rounded-lg bg-gray-50">
        <Trans i18nKey="list_editing_info" />
      </p>
    </section>
  );
};

export default ListEditingSection;
