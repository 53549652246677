interface DownloadButtonType {
  id: string;
  icon: string;
  paragraph: string;
  span: string;
  link: string;
}

const DownloadButton = ({
  id,
  icon,
  paragraph,
  span,
  link
}: DownloadButtonType) => {
  return (
    <button
      id={id}
      type="button"
      className="flex items-center justify-center h-12 px-3 mt-4 text-white bg-black border rounded-lg hover:bg-primary-600 hover:shadow-md sm:mr-2 last:sm:mr-0"
      onClick={() => {
        window.open(link);
      }}
    >
      <img src={icon} className="mr-1.5" alt="Listonic Logo" />
      <div className="flex flex-col items-start">
        <p className="text-xs font-normal">{paragraph}</p>
        <span className="text-base font-bold ">{span}</span>
      </div>
    </button>
  );
};

export default DownloadButton;
