import ReactPaginate from 'react-paginate';

interface PaginationType {
  pageCount: number;
  handlePageClick?: (event: any) => void;
  itemsPerPage: number;
  listOfElements: {}[];
  setItemOffset: (props: number) => void;
}

const Pagination = ({
  pageCount,
  itemsPerPage,
  listOfElements,
  setItemOffset
}: PaginationType) => {
  const handlePageClick = (event: any) => {
    const newOffset: number =
      (event.selected * itemsPerPage) % listOfElements.length;
    setItemOffset(newOffset);
  };

  return (
    <ReactPaginate
      nextLabel={
        <>
          <span className="sr-only">Next</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel={
        <>
          <span className="sr-only">Previous</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </>
      }
      pageClassName=""
      pageLinkClassName="pb-[8.8px] pt-[8.5px] px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      previousClassName=""
      previousLinkClassName="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      nextClassName="page-item"
      nextLinkClassName="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      breakLabel="..."
      breakClassName=""
      breakLinkClassName="pb-[8.8px] pt-[8.5px] px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      containerClassName="inline-flex items-center -space-x-px"
      activeClassName="py-2 leading-tight text-gray-300 bg-cover bg-gray-100 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
    />
  );
};

export default Pagination;
