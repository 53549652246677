import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

interface MenuDataType {
  id: string;
  href: string;
  text: string;
}

const Menu = () => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const menuData: MenuDataType[] = [
    {
      id: '0',
      href: '#list-editing',
      text: t('list_editing_all')
    },
    {
      id: '1',
      href: '#sharing',
      text: t('share_title')
    },
    {
      id: '2',
      href: '#settings',
      text: t('all_settings')
    },

    {
      id: '3',
      href: '#list-import',
      text: t('all_list_import')
    }
  ];

  return (
    <div className="p-8 border rounded-lg border-primary-200 w-max md:min-w-max h-max">
      <h2 className="mb-6 text-base font-bold text-gray-900 uppercase">Menu</h2>
      <ul>
        {menuData.map((data) => (
          <li
            key={data.id}
            id={data.id}
            className={`mb-4 last:mb-6 text-base ${
              data.id === selectedId ? 'text-primary-600' : 'text-gray-500'
            } hover:text-primary-600 font-medium`}
          >
            <a href={data.href} onClick={() => setSelectedId(data.id)}>
              {data.text}
            </a>
          </li>
        ))}
      </ul>
      <a href="https://listonic.com/contact-us/">
        <Button
          type="button"
          size="sm"
          bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
          textColor="text-white"
          fontWeight="font-medium"
          height="h-10"
          px="px-4"
          borderRadius="rounded-lg"
        >
          {t('help_contact_us')}
        </Button>
      </a>
    </div>
  );
};

export default Menu;
