interface ModalType {
  children: React.ReactNode;
  marginTop: string;
  mobileMarginTop?: string;
  width: string;
  headingText?: string;
  borderWrapper?: boolean;
  modalClose?: () => void;
}

const Modal = ({
  children,
  marginTop,
  mobileMarginTop,
  width,
  headingText,
  borderWrapper,
  modalClose
}: ModalType) => {
  return (
    <div className="absolute z-10 w-full h-[1000%] bg-gray-900/30">
      <div
        className={`flex flex-col items-center`}
        style={{ marginTop: marginTop }}
      >
        <div
          className={`flex flex-col ${width} bg-white rounded shadow overflow-y-auto`}
        >
          <div
            className={`px-5 py-6 text-sm text-gray-900 ${
              borderWrapper && 'border-b'
            }`}
          >
            <div
              className={`flex ${
                modalClose !== undefined ? 'justify-between' : 'justify-center'
              } font-medium items-center`}
            >
              <h1
                className={`${
                  modalClose !== undefined
                    ? 'text-lg font-semibold'
                    : 'text-2xl font-bold'
                }`}
              >
                {headingText}
              </h1>
              {modalClose !== undefined && (
                <button
                  type="button"
                  onClick={modalClose}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="top-right-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
