import { useState } from 'react';
import Button from '../../components/Button';
import create from '../../assets/images/create.svg';
import share from '../../assets/images/share.svg';
import open from '../../assets/images/open.svg';
import use from '../../assets/images/use.svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

const InfoSlider = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t('help_popup_step1_header'),
      text: t('help_popup_step1_text'),
      icon: create
    },
    {
      title: t('help_popup_step2_header'),
      text: t('help_popup_step2_text'),
      icon: share
    },
    {
      title: t('help_popup_step3_header'),
      text: t('help_popup_step3_text'),
      icon: open
    },
    {
      title: t('help_popup_step4_header'),
      text: t('help_popup_step4_text'),
      icon: use
    }
  ];

  const [activeSlider, setActiveSlider] = useState(false);

  const [slideCount, setSlideCount] = useState(0);

  return (
    <>
      <div className="w-full sm:w-[405px] border rounded-lg border-primary-600 px-4 mb-8 py-4">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-3 text-primary-600">
            <div>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="#34B524"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                ></path>
              </svg>
            </div>
            <div>
              <Trans i18nKey="help_popup_header" />
            </div>
          </div>
          <div>
            <Button
              type="button"
              size="sm"
              bgColor="bg-white"
              textColor="gray/900"
              fontWeight="font-medium"
              px="px-2"
              height="h-5"
              borderRadius="rounded-lg"
              onClickHandler={() => setActiveSlider((current) => !current)}
            >
              <svg
                className={classnames(
                  `w-4 h-4 ${!activeSlider && 'rotate-180'}`
                )}
                fill="none"
                stroke="#34B524"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 15l7-7 7 7"
                ></path>
              </svg>
            </Button>
          </div>
        </div>
        {activeSlider && (
          <div className="flex flex-col">
            <div className="my-3 text-sm">{t('help_popup_subtitle')} </div>
            <div className="flex flex-row items-center h-20 gap-3 sm:h-20">
              <div className="flex w-16">
                <img
                  className="w-16 max-w-none"
                  src={data[slideCount].icon}
                  alt="icon"
                ></img>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-base">{data[slideCount].title}</div>
                <div className="h-12 text-xs sm:h-10">
                  <Trans
                    i18nKey={data[slideCount].text}
                    components={{
                      1: (
                        <a
                          href="https://listonic.com/features/"
                          className="font-semibold hover:underline"
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-4">
              <div className="flex flex-row items-center gap-3">
                <div className="">
                  <Button
                    type="button"
                    size="sm"
                    bgColor="bg-white hover:bg-gray-200"
                    textColor="gray/900"
                    fontWeight="font-medium"
                    px="px-4"
                    height="h-9"
                    borderRadius="rounded-lg"
                    border="border border-gray-200"
                    disabled={slideCount === 0 && true}
                    onClickHandler={() =>
                      setSlideCount((current) => current - 1)
                    }
                  >
                    {t('all_previous')}
                  </Button>
                </div>

                <div>
                  <Button
                    type="button"
                    size="sm"
                    bgColor="bg-primary-600 hover:bg-primary-700"
                    textColor="text-white"
                    fontWeight="font-medium"
                    px="px-4"
                    height="h-9"
                    borderRadius="rounded-lg"
                    disabled={slideCount === 3 && true}
                    onClickHandler={() =>
                      setSlideCount((current) => current + 1)
                    }
                  >
                    {t('all_next')}
                  </Button>
                </div>
              </div>
              <div className="text-xs hover:underline text-primary-600">
                <Link to="/instruction">{t('help_popup_full_guide')}</Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InfoSlider;
