import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import { useAppContext } from '../../store/AppContext';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useAuthContext } from '../../store/AuthContext';
import { apiApp } from '../../api/index';

interface schemaType {
  name: string;
  source: string;
  items: {
    name: string;
    amount: string;
    unit: string;
    description: string;
  }[];
  utmParams: {
    campaign: string;
    source: string;
    medium: string;
  };
}
const CreateNewListModal = () => {
  const { setShowCreateNewListModal } = useAppContext();
  const { LOGGED_IN_AUTH_TOKEN } = useAuthContext();

  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 200).toString();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: { newList: '' },
    validateOnChange: false,
    onSubmit: async () => {
      const data = await handleGenerateLink();
      setShowCreateNewListModal(false);
      navigate('/list-creation' + '?listId=' + `${data.linkId}`);
    }
  });

  const schema: schemaType = {
    name: values.newList === '' ? t('all_new_list') : values.newList,
    source: 'Listonic',
    items: [],
    utmParams: {
      campaign: '',
      source: '',
      medium: ''
    }
  };

  const handleGenerateLink = async () => {
    try {
      const results = await apiApp.post('/api/link', schema, {
        headers: {
          Authorization: LOGGED_IN_AUTH_TOKEN
        }
      });
      return results.data;
    } catch (error: any) {
      return error;
    }
  };

  return (
    <Modal
      marginTop={`${margin}px`}
      mobileMarginTop="mt-56"
      width="w-81"
      headingText={t('newlist_btn')}
    >
      <div className="px-6">
        <Input
          id="newList"
          type="text"
          placeholder={t('all_new_list')}
          textColor="text-gray-900"
          width="w-full"
          height="h-12"
          size="base"
          fontWeight="font-medium"
          value={values.newList}
          onChange={handleChange}
        />
      </div>

      <div className="flex justify-between gap-4 px-6 py-4">
        <Button
          type="button"
          size="base"
          bgColor="bg-white focus:ring-gray-300 hover:bg-gray-100 focus:ring-4"
          textColor="text-gray-900"
          fontWeight="font-medium"
          px="px-11"
          height="h-12"
          border="border"
          borderRadius="rounded-lg"
          onClickHandler={() => setShowCreateNewListModal(false)}
        >
          {t('all_cancel')}
        </Button>
        <Button
          type="button"
          size="base"
          bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
          textColor="text-white"
          fontWeight="font-medium"
          px="px-11"
          height="h-12"
          borderRadius="rounded-lg"
          onClickHandler={handleSubmit}
        >
          {t('all_create')}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateNewListModal;
