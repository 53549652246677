import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useAppContext } from '../../store/AppContext';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import TextArea from '../../components/Textarea';
import useScrollPosition from '../../hooks/useScrollPosition';

interface TableStateType {
  name: string;
  amount: string;
  unit: string;
  description: string;
  id: number | null;
}

const NewItemForm = () => {
  const { t } = useTranslation();
  const { setShowNewItemForm, tableState, setTableState } = useAppContext();
  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 100).toString();
  const [addingNextItem, setAddingNextItem] = useState(false);
  const refInputFocus = useRef<HTMLInputElement>(null);

  const { handleChange, values, handleSubmit, errors, resetForm } = useFormik({
    initialValues: {
      newItem: '',
      newAmount: '',
      newUnit: '',
      newDescription: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      newItem: Yup.string().required(),
      newAmount: Yup.number().positive()
    }),
    onSubmit: () => {
      setTableState((current: TableStateType[]) => [
        ...current,
        {
          name: values.newItem,
          amount: values.newAmount.toString(),
          unit: values.newUnit,
          description: values.newDescription,
          id: tableState.length
        }
      ]);
      addingNextItem ? addAnotherItemHandler() : saveAndClose();
    }
  });

  const saveAndClose = () => {
    setShowNewItemForm(false);
    setAddingNextItem(false);
  };

  const addAnotherItemHandler = () => {
    resetForm();
    setAddingNextItem(false);
    onFocusInputClick();
  };

  const modalCloseHandler = () => {
    setShowNewItemForm(false);
  };

  const onFocusInputClick = () => {
    if (refInputFocus !== null) {
      refInputFocus?.current?.focus();
    }
  };

  return (
    <>
      <Modal
        marginTop={`${margin}px`}
        width="w-80 sm:w-120"
        headingText={t('add_new_item')}
        modalClose={modalCloseHandler}
        borderWrapper
      >
        <div className="flex flex-col justify-center gap-3 px-6 py-6">
          <div className="relative flex flex-col w-full">
            <label
              htmlFor="newItem"
              className="text-sm font-medium text-gray-900"
            >
              {t('list_item')}
            </label>
            <div
              className={`mt-2 h-12 ${
                errors.newItem ? 'bg-red-50' : 'bg-gray-50'
              } border ${
                errors.newItem ? 'border-red-500' : 'border-gray-300'
              } ${
                errors.newItem ? 'text-red-700' : 'text-gray-900'
              } text-sm rounded-lg ${
                errors.newItem
                  ? 'focus-within:border-red-500'
                  : 'focus-within:border-primary-600'
              } focus:ring-0 placeholder:text-gray-500`}
            >
              <input
                ref={refInputFocus}
                id="newItem"
                value={values.newItem}
                onChange={handleChange}
                type="text"
                className="w-full h-full text-base font-medium truncate bg-transparent border-none rounded-lg focus:ring-0 focus:border-transparent"
              />
            </div>
            {errors.newItem && (
              <p className="text-sm text-red-600">
                {t('error_message_field_is_required')}
              </p>
            )}
          </div>
          <div className="flex justify-between">
            <Input
              id="newAmount"
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              label={t('list_amount')}
              textColor="text-gray-900"
              width="w-32 sm:w-8/12"
              height="h-12"
              size="text-base"
              fontWeight="font-medium"
              error={!!errors.newAmount}
              errorMessage="Wrong amount"
              value={values.newAmount}
              maxLength={6}
              onChange={handleChange}
            />
            <Input
              id="newUnit"
              type="text"
              label={t('list_unit')}
              textColor="text-gray-900"
              width="w-32 sm:w-36"
              height="h-12"
              size="text-base"
              fontWeight="font-medium"
              value={values.newUnit}
              onChange={handleChange}
            />
          </div>
          <TextArea
            id="newDescription"
            label={t('list_description')}
            textColor="text-gray-900"
            width="w-full"
            size="text-base"
            fontWeight="font-medium"
            value={values.newDescription}
            onChange={handleChange}
            placeholder={t('note_hint')}
          />
        </div>
        <div className="flex justify-center gap-3 py-6 border-t sm:justify-start sm:pl-6">
          <Button
            type="submit"
            size="sm"
            bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
            textColor="text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            borderRadius="rounded-lg"
            onClickHandler={() => {
              handleSubmit();
              setAddingNextItem(false);
            }}
          >
            {t('save_close_btn')}
          </Button>
          <Button
            type="submit"
            size="sm"
            bgColor="bg-white focus:ring-primary-300 hover:bg-primary-600 focus:ring-4"
            textColor="text-primary-600 hover:text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            border="border border-primary-600"
            borderRadius="rounded-lg"
            onClickHandler={() => {
              handleSubmit();
              setAddingNextItem(true);
            }}
          >
            {t('add_another_item_btn')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default NewItemForm;
