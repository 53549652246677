export const UserIcon = ({ strokeColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:stroke-#1C6013"
    >
      <path
        d="M9.88566 7.05229C10.3858 6.55219 10.6667 5.87391 10.6667 5.16667C10.6667 4.45942 10.3858 3.78115 9.88566 3.28105C9.38556 2.78095 8.70728 2.5 8.00004 2.5C7.2928 2.5 6.61452 2.78095 6.11442 3.28105C5.61433 3.78115 5.33337 4.45942 5.33337 5.16667C5.33337 5.87391 5.61433 6.55219 6.11442 7.05229C6.61452 7.55238 7.2928 7.83333 8.00004 7.83333C8.70728 7.83333 9.38556 7.55238 9.88566 7.05229Z"
        stroke={strokeColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.70021 11.2002C5.57538 10.325 6.76236 9.83333 8.00004 9.83333C9.23772 9.83333 10.4247 10.325 11.2999 11.2002C12.175 12.0753 12.6667 13.2623 12.6667 14.5H3.33337C3.33337 13.2623 3.82504 12.0753 4.70021 11.2002Z"
        stroke={strokeColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
