import axios from 'axios';
import { SuccessResponse } from '@greatsumini/react-facebook-login';
import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../api/apiRoutes';
import { logInDataTypes, signUpDataTypes } from './utils';
import { apiAuth } from '../api/index';

export type AuthContextType = {
  isLoginForm: boolean;
  setIsLoginForm: (value: boolean) => void;
  showForgotPasswordModal: boolean;
  setShowForgotPasswordModal: (value: boolean) => void;
  isEmailSent: boolean;
  setIsEmailSent: (value: boolean) => void;
  signUp: (value: any) => void;
  logIn: (value: any) => void;
  logOut: () => void;
  loginGoogle: (res: any) => void;
  loginFacebook: (res: any) => void;
  isLoggedIn: boolean;
  authErrorLogin: boolean;
  setAuthErrorCreateAccount: (value: boolean) => void;
  authErrorCreateAccount: boolean;
  setAuthErrorLogin: (value: boolean) => void;
  forgotPassword: (value: string) => void;
  rememberMe: boolean;
  setRememberMe: (value: boolean) => void;
  getUsername: () => void;
  username: string;
  setUsername: (value: string) => void;
  loginApple: (res: any) => void;
  LOGGED_IN_AUTH_TOKEN: string;
};

export const AuthContext = createContext<AuthContextType>({
  isLoginForm: true,
  setIsLoginForm: () => console.warn('no auth provider'),
  showForgotPasswordModal: false,
  setShowForgotPasswordModal: () => console.warn('no auth provider'),
  isEmailSent: false,
  setIsEmailSent: () => console.warn('no auth provider'),
  signUp: () => console.warn('no auth provider'),
  logIn: () => console.warn('no auth provider'),
  logOut: () => console.warn('no auth provider'),
  loginGoogle: () => console.warn('no auth provider'),
  loginFacebook: () => console.warn('no auth provider'),
  isLoggedIn: false,
  authErrorLogin: false,
  setAuthErrorLogin: () => console.warn('no auth provider'),
  setAuthErrorCreateAccount: () => console.warn('no auth provider'),
  authErrorCreateAccount: false,
  forgotPassword: () => console.warn('no auth provider'),
  rememberMe: false,
  setRememberMe: () => console.warn('no auth provider'),
  getUsername: () => console.warn('no auth provider'),
  username: '',
  setUsername: () => console.warn('no auth provider'),
  loginApple: () => console.warn('no auth provider'),
  LOGGED_IN_AUTH_TOKEN: ''
});

const CLIENT_ID = 'listonicv2';
const REDIRECT_URL = 'https://listonicv2api.jestemkucharzem.pl';
const CLIENT_SECRET = 'fjdfsoj9874jdfhjkh34jkhffdfff';
export const AUTH_TOKEN = 'Bearer ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET);

export const AuthContextProvider = (props: any) => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [authErrorLogin, setAuthErrorLogin] = useState(false);
  const [authErrorCreateAccount, setAuthErrorCreateAccount] = useState(false);
  const [username, setUsername] = useState('');
  const isLoggedIn = !!localStorage.getItem('refreshToken') !== undefined;
  const LOGGED_IN_AUTH_TOKEN = 'Bearer ' + localStorage.getItem('accessToken');

  const forgotPassword = async (email: string) => {
    try {
      await axios
        .post(
          `${config.BASE_API.API_AUTH_ENDPOINT}/api/account/requestresetpassword/?email=${email}&url=` +
            `${encodeURIComponent(
              `https://app.listonic.com/reset_password/${encodeURIComponent(
                email
              )}/`
            )}`,
          {},
          {}
        )
        .then(() => setIsEmailSent(true));
    } catch (error: any) {
      return error;
    }
  };

  const signUp = async (data: signUpDataTypes) => {
    try {
      await axios
        .post(
          `${config.BASE_API.API_AUTH_ENDPOINT}${config.apiRoutes.ACCOUNT_REGISTER}`,
          data
        )
        .then(() => {
          let username = data.UserName;
          let password = data.Password;
          const logInData = { username, password };
          logIn(logInData);
        });
    } catch (error: any) {
      if (error.response.status === 400) {
        setAuthErrorCreateAccount(true);
      }
      return error;
    }
  };

  const logIn = async (data: logInDataTypes) => {
    let schema =
      'username=' +
      encodeURIComponent(data.username) +
      '&password=' +
      encodeURIComponent(data.password) +
      '&client_id=' +
      CLIENT_ID +
      '&redirect_uri=' +
      encodeURIComponent(REDIRECT_URL) +
      '&client_secret=' +
      encodeURIComponent(CLIENT_SECRET);

    try {
      await axios
        .post(
          `${config.BASE_API.API_AUTH_ENDPOINT}${config.apiRoutes.LOGIN_EXTENDED_PASSWORD}`,
          schema,
          {
            headers: {
              ClientAuthorization: AUTH_TOKEN
            }
          }
        )
        .then((res: any) => {
          localStorage.setItem('refreshToken', res.data.refresh_token);
          localStorage.setItem('accessToken', res.data.access_token);
        })
        .then(() => navigate('/my-lists'));
    } catch (error: any) {
      if (error.response.status === 400) {
        setAuthErrorLogin(true);
      }
      return error;
    }
  };

  const loginGoogle = async (res: any) => {
    let schema =
      'token=' +
      res.accessToken +
      '&client_id=' +
      CLIENT_ID +
      '&redirect_uri=' +
      encodeURIComponent(REDIRECT_URL) +
      '&client_secret=' +
      encodeURIComponent(CLIENT_SECRET);
    try {
      await axios
        .post(
          `${config.BASE_API.API_AUTH_ENDPOINT}${config.apiRoutes.LOGIN_EXTENDED_GOOGLE}`,
          schema,
          {
            headers: {
              ClientAuthorization: AUTH_TOKEN
            }
          }
        )
        .then((res: any) => {
          localStorage.setItem('refreshToken', res.data.refresh_token);
          localStorage.setItem('accessToken', res.data.access_token);
        })
        .then(() => navigate('/my-lists'));
    } catch (error: any) {
      return error;
    }
  };

  const loginFacebook = async (res: SuccessResponse) => {
    let schema =
      'token=' +
      res.accessToken +
      '&client_id=' +
      CLIENT_ID +
      '&redirect_uri=' +
      encodeURIComponent(REDIRECT_URL) +
      '&client_secret=' +
      encodeURIComponent(CLIENT_SECRET);
    try {
      await axios
        .post(
          `${config.BASE_API.API_AUTH_ENDPOINT}${config.apiRoutes.LOGIN_EXTENDED_FACEBOOK}`,
          schema,
          {
            headers: {
              ClientAuthorization: AUTH_TOKEN
            }
          }
        )
        .then((res: any) => {
          localStorage.setItem('refreshToken', res.data.refresh_token);
          localStorage.setItem('accessToken', res.data.access_token);
        })
        .then(() => navigate('/my-lists'));
    } catch (error: any) {
      return error;
    }
  };
  const client_id = 'com.listonic.linkGenerator';
  const team_id = 'VFHNGPJXL2';
  const key_id = '7XTBL588QK';
  const redirect_uri = 'https://linkgenerator.poc.listonic.com/';
  const scope = 'name email';
  const secret =
    'MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQg6xiSuDFQXdikFvqlAjTnnzXtflBFYD3G7EYVGGiQgQOgCgYIKoZIzj0DAQehRANCAATOkyWqAN2vAYImqeMNvhuWshItAqXc9PU6oP1inkvuUKJo6ea13WPm5zVTrThG0XBWEAV6/dLuzoC7C6XcZHJ';

  // const clientSecret = {
  //   clientID: client_id, // Apple Client ID
  //   teamID: team_id, // Apple Developer Team ID.
  //   privateKey: secret, // private key associated with your client ID. -- Or provide a `privateKeyPath` property instead.
  //   keyIdentifier: key_id, // identifier of the private key.
  //   expAfter: 15777000 // Unix time in seconds after which to expire the clientSecret JWT. Default is now+5 minutes.
  // };

  const loginApple = async (res: any) => {
    let schema =
      '&client_id=' +
      encodeURIComponent(client_id) +
      '&client_secret=' +
      // encodeURIComponent(clientSecret) +
      '&code=' +
      encodeURIComponent(res.code) +
      '&grant_type=authorization_code' +
      '&redirect_uri=' +
      encodeURIComponent(redirect_uri);

    try {
      await axios
        .post('https://appleid.apple.com/auth/token?' + schema)
        .then((res: any) => {
          console.log(res);
        });
    } catch (error: any) {
      return error;
    }
  };

  const getUsername = async () => {
    try {
      await apiAuth
        .get(`${config.apiRoutes.GET_ACCOUNT_PROFILE}`, {
          headers: {
            Authorization: LOGGED_IN_AUTH_TOKEN
          }
        })
        .then((res) => {
          setUsername(res.data.Realname);
        });
    } catch (error: any) {
      return error;
    }
  };

  const logOut = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        isLoginForm,
        setIsLoginForm,
        showForgotPasswordModal,
        setShowForgotPasswordModal,
        isEmailSent,
        setIsEmailSent,
        signUp,
        logIn,
        logOut,
        loginGoogle,
        loginFacebook,
        isLoggedIn,
        authErrorLogin,
        setAuthErrorLogin,
        authErrorCreateAccount,
        setAuthErrorCreateAccount,
        forgotPassword,
        rememberMe,
        setRememberMe,
        getUsername,
        username,
        setUsername,
        loginApple,
        LOGGED_IN_AUTH_TOKEN
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
