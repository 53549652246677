import { useState } from 'react';
import Logo from '../../../assets/icons/Logo.svg';
import { UserIcon } from '../../../assets/icons/user';
import Dropdown from './Dropdown';
import Hamburger from 'hamburger-react';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  let path = window.location.pathname;
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeSideBar, setActiveSideBar] = useState(false);
  const [strokeColor, setStrokeColor] = useState(false);

  const dropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const sidebarToggle = () => {
    setActiveSideBar(!activeSideBar);
  };

  return (
    <nav className="fixed w-full bg-white border-b border-b-gray-200 py-2.5 z-10">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <Link to="/my-lists">
          <div className="flex items-center">
            <img src={Logo} className="h-6 mr-3 w-34" alt="Listonic Logo" />
          </div>
        </Link>

        <div className="block md:hidden">
          <Hamburger
            toggle={sidebarToggle}
            toggled={activeSideBar}
            size={25}
            color="#0F172A"
            direction="right"
            rounded={true}
            distance="sm"
          />
        </div>
        {activeSideBar && (
          <div className="absolute top-0 right-0 w-full md:hidden mt-17 sm:w-max sm:right-4">
            <Sidebar />
          </div>
        )}
        <div
          className="relative hidden w-full md:block md:w-auto"
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
            <Link to="/instruction">
              <li
                className={`relative block py-2 pl-3 pr-4 text-base font-medium ${
                  path === '/instruction' ? 'text-primary-600' : 'text-gray-900'
                } rounded hover:text-primary-600 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0`}
              >
                {t('all_help')}
              </li>
            </Link>
            <Link to="/my-lists">
              <li
                className={`block py-2 pl-3 pr-4 text-base font-medium ${
                  path === '/my-lists' ? 'text-primary-600' : 'text-gray-900'
                } rounded hover:text-primary-600 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0`}
              >
                {t('all_mylists')}
              </li>
            </Link>
            <li
              onMouseEnter={() => setStrokeColor(true)}
              onMouseLeave={() => setStrokeColor(false)}
            >
              <button
                onClick={dropdownToggle}
                className={`block py-2 pl-3 pr-4 text-base font-medium text-gray-900 rounded hover:text-primary-600 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0`}
              >
                <div className="flex items-center gap-1">
                  <UserIcon strokeColor={strokeColor ? '#34B524' : '#0F172A'} />
                  {t('all_profile')}
                </div>
              </button>
            </li>
          </ul>
          {showDropdown && (
            <div className="absolute top-0 right-0 w-max mt-17">
              <Dropdown />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
