import classnames from 'classnames';

interface ButtonType {
  type: 'button' | 'submit';
  size: string;
  bgColor: string;
  textColor: string;
  children: React.ReactNode;
  fontWeight: string;
  width?: string;
  height: string;
  border?: string;
  borderRadius?: string;
  gap?: string;
  px?: string;
  disabled?: boolean;
  onClickHandler?: ((res?: any) => void) | ((res: any) => Promise<any>);
}

const Button = ({
  type,
  size,
  bgColor,
  textColor,
  children,
  fontWeight,
  width,
  height,
  border,
  borderRadius,
  gap,
  px,
  disabled,
  onClickHandler
}: ButtonType) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClickHandler}
      className={classnames(
        `${width} ${height} ${disabled ? 'bg-gray-200' : bgColor} ${
          disabled ? 'text-gray-400' : textColor
        } ${fontWeight} ${border} ${px} ${borderRadius} flex items-center justify-center ${gap}`,
        {
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-xl': size === 'lg'
        }
      )}
    >
      {children}
    </button>
  );
};

export default Button;
