const API_AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;

export const config = {
  BASE_API: { API_AUTH_ENDPOINT },
  apiRoutes: {
    ACCOUNT_REGISTER: '/api/account/register',
    LOGIN_EXTENDED_PASSWORD:
      '/api/loginextended?provider=password&autoMerge=1&autoDestruct=1',
    LOGIN_EXTENDED_GOOGLE:
      '/api/loginextended?provider=google&autoMerge=1&autoDestruct=1',
    LOGIN_EXTENDED_FACEBOOK:
      '/api/loginextended?provider=Facebook&autoMerge=1&autoDestruct=1',
    LOGIN_EXTENDED_APPLE:
      '/api/loginextended?provider=apple&autoMerge=1&autoDestruct=1',
    LOGIN_EXTENDED_REFRESH_TOKEN:
      '/api/loginextended?provider=refresh_token&autoMerge=1&autoDestruct=1',
    GET_ACCOUNT_PROFILE: '/api/account/profile'
  }
};
