import Button from '../../components/Button';
import ErrorImg from '../../assets/images/404-image.svg';
import ArrowIcon from '../../assets/icons/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col-reverse md:flex-row justify-around items-center md:h-screen pb-10">
      <div className="flex flex-col ml-5 mr-5 md:ml-20 md:mr-20 text-center items-center md:text-start md:items-start">
        <p className="uppercase text-base text-gray-500 mt-10 md:mt-0">
          {t('404_error')}
        </p>
        <h1 className="text-7xl font-medium text-gray-900 leading-normal">
          {t('404_title')}
        </h1>
        <p className="text-xl text-gray-500 mt-6 mb-6">{t('404_text')}</p>
        <Button
          type="button"
          size="base"
          bgColor="neutral-25"
          textColor="text-gray-900"
          fontWeight="font-medium"
          height="h-14"
          border="border border-gray-200"
          borderRadius="rounded-lg"
          px="px-6"
          onClickHandler={() => navigate('/')}
        >
          <img src={ArrowIcon} alt="arrow icon" className="mr-3" />
          {t('back_to_main_btn')}
        </Button>
      </div>
      <div className="mt-16 ml-20 mr-20 md:mr-20">
        <img src={ErrorImg} alt="" />
      </div>
    </div>
  );
};

export default ErrorPage;
