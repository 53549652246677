import { FC, useState, useRef } from 'react';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../components/Dropdown';
import { useOnClickOutside } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../store/AppContext';
import { QRCodeCanvas } from 'qrcode.react';
import { useWindowSize } from '../../hooks/useWindowSize';

interface ListLineProps {
  id: number;
  title: string;
  itemNumber: number;
  url: string;
  isWorkInProgress: boolean;
}

const ListLine: FC<ListLineProps> = ({
  id,
  title,
  itemNumber,
  url,
  isWorkInProgress
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const qrRef = useRef<HTMLDivElement | null>(null);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [strokeColor, setStrokeColor] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);

  const { setShowDeleteListModal, setActiveListId } = useAppContext();

  const toggleDropdownModal = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const handleClickInside = (e: any) => {
    e.stopPropagation();
    toggleDropdownModal();
  };

  const handleCopyLink = () => {
    setCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleDownloadQR = () => {
    downloadQRCode();
  };

  const downloadQRCode = () => {
    if (qrRef.current !== null) {
      let canvas = qrRef.current.querySelector('canvas');
      if (canvas !== null) {
        let image = canvas.toDataURL('image/png');
        let anchor = document.createElement('a');
        anchor.href = image;
        anchor.download = `qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  const dropdownData = [
    {
      id: '1',
      text: t('all_delete'),
      textColor: 'text-red-600',
      icon: (
        <svg
          className="w-5 h-5"
          fill="none"
          stroke={strokeColor ? '#fff' : '#E02424'}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          ></path>
        </svg>
      ),
      onClick: (e: Event) => {
        e.stopPropagation();
        setShowDeleteListModal(true);
        setActiveListId(id);
      }
    },
    {
      id: '2',
      text: t('all_copy_link'),
      icon: (
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
          ></path>
        </svg>
      ),
      onClick: (e: Event) => {
        e.stopPropagation();
        handleCopyLink();
        toggleDropdownModal();
      }
    },
    {
      id: '3',
      text: t('all_download_qr'),
      icon: (
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
          ></path>
        </svg>
      ),
      onClick: (e: Event) => {
        e.stopPropagation();
        handleDownloadQR();
        toggleDropdownModal();
      }
    }
  ];

  return (
    <>
      <div
        onClick={() => navigate('/list-creation' + '?listId=' + `${id}`)}
        className="flex flex-row items-center justify-between w-full gap-4 px-5 py-3 mt-3 mb-2 border border-gray-200 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200 sm:py-5 sm:px-4"
      >
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row sm:px-2 w-6/7 md:w-full">
          <div className="flex flex-row items-center justify-between w-56 sm:w-64 md:w-96">
            <h3 className="text-sm font-semibold truncate">{title}</h3>
            {isWorkInProgress && (
              <span className="bg-red-100 text-red-800 text-xs mr-2 px-2.5 py-1 rounded dark:bg-red-200 dark:text-red-900 text-center">
                {t('unsaved_list')}
              </span>
            )}
          </div>

          <div className="w-56 truncate sm:w-max">
            <p className="text-sm truncate">
              {itemNumber}{' '}
              {itemNumber === 1
                ? t('list_items.one')
                : itemNumber === 2 || itemNumber === 3 || itemNumber === 4
                ? t('list_items.few')
                : itemNumber > 4
                ? t('list_items.many')
                : t('list_items.other')}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3 -mr-2 w-1/7 md:w-1/4">
          <div
            className="hidden md:flex"
            onMouseEnter={() => setStrokeColor(true)}
            onMouseLeave={() => setStrokeColor(false)}
          >
            <Button
              type="button"
              size="base"
              bgColor="hover:bg-red-600"
              textColor="text-red-600 hover:text-white"
              fontWeight="text-base font-medium"
              px="px-2.5 py-2"
              height=""
              border="border-red-600 border"
              borderRadius="rounded-lg"
              gap="gap-1"
              onClickHandler={(e: Event) => {
                e.stopPropagation();
                setShowDeleteListModal(true);
                setActiveListId(id);
              }}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke={strokeColor ? '#fff' : '#E02424'}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
              </svg>
              <p className="text-xs ">{t('all_delete')}</p>
            </Button>
          </div>
          {copied && (
            <span className="absolute z-50 translate-x-[-20px] translate-y-[-40px] sm:translate-x-[210px] sm:translate-y-[0px] bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-1.5 rounded dark:bg-green-200 dark:text-green-900">
              {t('copied_link')}
            </span>
          )}
          <div ref={ref}>
            {showDropdown && width >= 834 && (
              <div className="absolute -translate-x-32 mt-11">
                <Dropdown dropdownData={dropdownData.slice(1)} />
              </div>
            )}
            {showDropdown && width < 834 && (
              <div className="absolute -translate-x-28 sm:-translate-x-32 mt-11">
                <Dropdown dropdownData={dropdownData} />
              </div>
            )}
            <div
              className="cursor-pointer"
              onClick={(e) => handleClickInside(e)}
            >
              <button
                id="dropdownMenuIconButton"
                data-dropdown-toggle="dropdownDots"
                className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-transparent rounded-full hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                type="button"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                </svg>
              </button>
            </div>
            <div>
              <div className="absolute hidden" ref={qrRef}>
                <QRCodeCanvas
                  id="qrCode"
                  value={url}
                  size={175}
                  bgColor="white"
                  level={'H'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLine;
