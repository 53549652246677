import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useAuthContext } from '../../../store/AuthContext';
import SendImg from '../../../assets/images/send-img.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { useTranslation } from 'react-i18next';

const EMAIL_REGEX = /^[-\w.!#$%&'*+=?`(){|}~^-]+@([-\w]+\.)+[a-z]+$/i;

const ForgotPassword = () => {
  const {
    isEmailSent,
    setIsEmailSent,
    setShowForgotPasswordModal,
    forgotPassword
  } = useAuthContext();
  const { t } = useTranslation();
  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 180).toString();

  const modalCloseHandler = () => {
    setShowForgotPasswordModal(false);
    setIsEmailSent(false);
  };

  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      forgotPasswordEmail: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      forgotPasswordEmail: Yup.string()
        .matches(EMAIL_REGEX, t('error_message_wrong_email'))
        .required(t('error_message_field_is_required'))
    }),

    onSubmit: () => {
      forgotPassword(values.forgotPasswordEmail);
    }
  });

  return (
    <>
      <Modal
        marginTop={`${margin}px`}
        mobileMarginTop="mt-56"
        width="w-81"
        headingText={t('login_forgot_password')}
        modalClose={modalCloseHandler}
      >
        {isEmailSent ? (
          <>
            <div className="flex flex-col items-center py-6">
              <img src={SendImg} alt="" className="w-40 h-40 mr-10" />
              <p className="px-6 text-base text-center text-black">
                {t('login_forgot_password_sent')}
              </p>
            </div>

            <div className="flex justify-center py-6">
              <Button
                type="button"
                size="sm"
                bgColor="bg-white focus:ring-primary-300 hover:bg-primary-600 focus:ring-0"
                textColor="text-primary-600 hover:text-white"
                fontWeight="font-medium"
                height="h-10"
                px="px-5"
                border="border border-primary-600"
                borderRadius="rounded-lg"
                onClickHandler={modalCloseHandler}
              >
                {t('login_forgot_password_close')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center pb-6">
              <p className="px-6 mb-3 text-sm text-gray-900">
                {t('login_forgot_password_text')}
              </p>
              <Input
                id="forgotPasswordEmail"
                type="text"
                label={t('email')}
                placeholder="name@company.com"
                textColor="text-gray-900"
                width="w-74"
                height="h-10"
                size="sm"
                fontWeight="font-medium"
                error={!!errors.forgotPasswordEmail}
                errorMessage="Wrong email"
                value={values.forgotPasswordEmail}
                onChange={handleChange}
                iconPosition="absolute right-4 top-3.5 z-30"
              />
            </div>
            <div className="flex justify-center px-6 pb-8">
              <Button
                type="submit"
                size="sm"
                bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
                textColor="text-white"
                fontWeight="font-medium"
                height="h-10"
                width="w-full"
                borderRadius="rounded-lg"
                onClickHandler={handleSubmit}
              >
                {t('reset_password_btn')}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ForgotPassword;
