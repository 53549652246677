import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';

interface LinkDataType {
  id: number;
  link: string;
  text: string;
}

const Sidebar = () => {
  const { t } = useTranslation();

  const linkData: LinkDataType[] = [
    { id: 1, link: '/instruction', text: t('all_help') },
    { id: 2, link: '/my-lists', text: t('all_mylists') }
  ];
  return (
    <Dropdown>
      {linkData.map((data) => (
        <div key={data.id}>
          <Link to={data.link}>
            <div className="px-4 py-3 text-sm text-gray-900 hover:bg-gray-100">
              {data.text}
            </div>
          </Link>
        </div>
      ))}
    </Dropdown>
  );
};

export default Sidebar;
