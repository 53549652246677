import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import { useAuthContext } from '../../../store/AuthContext';

const EMAIL_REGEX = /^[-\w.!#$%&'*+=?`(){|}~^-]+@([-\w]+\.)+[a-z]+$/i;

const CreateAccountForm = () => {
  const { signUp, authErrorCreateAccount } = useAuthContext();
  const { t } = useTranslation();

  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      createLogin: '',
      createPassword: '',
      terms_and_condition: false
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      createLogin: Yup.string()
        .matches(EMAIL_REGEX, t('error_message_wrong_email'))
        .required(t('error_message_field_is_required')),
      createPassword: Yup.string()
        .min(6, t('error_message_wrong_password'))
        .required(t('error_message_field_is_required')),
      terms_and_condition: Yup.boolean().oneOf([true])
    }),

    onSubmit: () => {
      const data = {
        UserName: values.createLogin,
        Email: values.createLogin,
        Password: values.createPassword,
        ConfirmPassword: values.createPassword
      };

      signUp(data);
    }
  });

  return (
    <>
      <div className="grid gap-6 sm:grid-cols-2">
        <Input
          id="createLogin"
          type="text"
          label={t('email')}
          placeholder="name@company.com"
          size="sm"
          textColor="text-gray-900"
          value={values.createLogin}
          onChange={handleChange}
          height="h-10"
          error={!!errors.createLogin || authErrorCreateAccount}
          errorMessage={errors.createLogin}
        />
        {authErrorCreateAccount && (
          <p className="-mt-5 text-sm text-red-600">
            {t('error_message_create_account')}
          </p>
        )}
        <Input
          id="createPassword"
          type="password"
          label={t('password')}
          placeholder="••••••••"
          size="sm"
          textColor="text-gray-900"
          value={values.createPassword}
          onChange={handleChange}
          height="h-10"
          error={!!errors.createPassword || authErrorCreateAccount}
          errorMessage={errors.createPassword}
        />
      </div>

      <div className="flex justify-between w-full mb-10">
        <Checkbox
          errorMessage={t('error_message_terms_and_conditions')}
          id="terms_and_condition"
          itemsPosition="items-start"
          label={
            <Trans
              i18nKey="signup_terms_privacy"
              components={{
                1: (
                  <a
                    href="https://listonic.com/terms-of-service/"
                    className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
                2: (
                  <a
                    href="https://listonic.com/privacy/"
                    className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                )
              }}
            />
          }
          checked={values.terms_and_condition}
          onClickHandler={handleChange}
          error={!!errors.terms_and_condition}
        />
      </div>
      <div className="mt-6">
        <Button
          type="submit"
          size="base"
          bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
          textColor="text-white"
          fontWeight="font-medium"
          width="w-full"
          height="h-12"
          borderRadius="rounded-lg"
          onClickHandler={handleSubmit}
        >
          {t('sign_up_btn')}
        </Button>
      </div>
    </>
  );
};

export default CreateAccountForm;
