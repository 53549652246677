import axios from 'axios';
import { setupInterceptorsTo } from './interseptors';

const APP_ENDPOINT = process.env.REACT_APP_BUTTON_POC_URL;
const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;

export const apiApp = setupInterceptorsTo(
  axios.create({
    baseURL: APP_ENDPOINT,
    headers: {
      'Content-Type': 'application/json'
    }
  })
);

export const apiAuth = setupInterceptorsTo(
  axios.create({
    baseURL: AUTH_ENDPOINT,
    headers: {
      'Content-Type': 'application/json'
    }
  })
);
