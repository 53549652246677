import FacebookLogin from '@greatsumini/react-facebook-login';
import ListLogo from '../../assets/icons/list-logo.svg';
import GoogleLogin from 'react-google-login';
import { GoogleLoginIcon } from '../../assets/icons/google';
import { FacebookLoginIcon } from '../../assets/icons/facebook-login-icon';
import { AppleLoginIcon } from '../../assets/icons/apple-login-icon';
import LoginForm from './LoginForm';
import { useAuthContext } from '../../store/AuthContext';
import CreateAccountForm from './CreateAccountForm';
import ForgotPassword from './ForgotPassword';
import Broccoli_Apple_Image from '../../assets/images/Apple&&Brocolli.svg';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { useTranslation } from 'react-i18next';
import AppleLogin from 'react-apple-login';
import LangSelector from '../../components/LangSelector';

const Auth = () => {
  const {
    isLoginForm,
    setIsLoginForm,
    showForgotPasswordModal,
    loginGoogle,
    loginFacebook,
    loginApple
  } = useAuthContext();
  const { t } = useTranslation();

  const facebookId = process.env.REACT_APP_FACEBOOK_ID as string;
  const googleId = process.env.REACT_APP_GOOGLE_API_TOKEN as string;

  document.body.setAttribute('style', 'background: #F8FAFC');
  useDisableBodyScroll(showForgotPasswordModal);

  return (
    <>
      {showForgotPasswordModal && <ForgotPassword />}
      <div className="py-10 lg:py-5 lg:items-center lg:h-screen lg:flex">
        <div className="grid max-w-screen-xl mx-auto lg:gap-20 lg:grid-cols-12 max-w-7xl">
          <div className="w-[22rem] sm:w-full place-self-center lg:col-span-6">
            <div className="p-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:max-w-xl sm:p-8">
              <div className="inline-flex items-center mb-4 text-xl font-semibold text-gray-900 dark:text-white">
                <img className="w-8 h-8 mr-2" src={ListLogo} alt="logo" />
                Listonic
              </div>
              <h1 className="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                {isLoginForm ? t('login_welcome') : t('signup_subheader')}
              </h1>
              <p className="text-sm font-light text-gray-500 dark:text-gray-300">
                {isLoginForm ? t('login_no_account') : t('signup_have_account')}{' '}
                <button
                  onClick={() => setIsLoginForm(!isLoginForm)}
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  {isLoginForm ? t('sign_up_btn') : t('login_btn')}
                </button>
              </p>
              <div className="mt-4 space-y-6 sm:mt-6">
                {isLoginForm ? <LoginForm /> : <CreateAccountForm />}
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-gray-200 dark:bg-gray-700"></div>
                  <div className="px-5 text-center text-gray-500 dark:text-gray-400">
                    {t('login_or')}
                  </div>
                  <div className="w-full h-[1px] bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div className="flex flex-col space-y-3">
                  <GoogleLogin
                    clientId={googleId}
                    render={({ onClick }) => (
                      <button
                        onClick={onClick}
                        className="gap-3 w-full inline-flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        <GoogleLoginIcon />
                        {t('login_google')}
                      </button>
                    )}
                    onSuccess={loginGoogle}
                  />
                  <FacebookLogin
                    appId={facebookId}
                    initParams={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      version: 'v17.0'
                    }}
                    onSuccess={(response) => {
                      void loginFacebook(response);
                    }}
                    onFail={(error) => {
                      console.error('Facebook Login Failed!', error);
                    }}
                    render={({ onClick }) => (
                      <button
                        onClick={onClick}
                        className="gap-3 w-full inline-flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        <FacebookLoginIcon />
                        {t('login_facebook')}
                      </button>
                    )}
                  />
                  {/* <AppleLogin
                    clientId="com.listonic.linkGenerator"
                    redirectURI="https://linkgenerator.poc.listonic.com/"
                    // usePopup={true}
                    scope="email name"
                    responseMode="query"
                    callback={loginApple}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="gap-3 w-full inline-flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        <AppleLoginIcon />
                        {t('login_apple')}
                      </button>
                    )}
                  /> */}
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-4 ml-3 lg:justify-start">
              <LangSelector />
            </div>
          </div>
          <div className="mr-auto place-self-center lg:col-span-6">
            <img
              className="hidden lg:flex"
              src={Broccoli_Apple_Image}
              alt="illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
