const SocialMedia = () => {
  return (
    <div>
      <ul className="flex flex-row justify-between w-32">
        <li>
          <a
            href="https://www.facebook.com/listonic"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-40"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/listonic"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-40"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC_t0hWBg7MwbzaMYutqsx8Q"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-40"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M23.7609 7.94492C23.7609 7.94492 23.5266 6.29023 22.8047 5.56367C21.8906 4.60742 20.8688 4.60273 20.4 4.54648C17.0438 4.30273 12.0047 4.30273 12.0047 4.30273H11.9953C11.9953 4.30273 6.95625 4.30273 3.6 4.54648C3.13125 4.60273 2.10938 4.60742 1.19531 5.56367C0.473438 6.29023 0.24375 7.94492 0.24375 7.94492C0.24375 7.94492 0 9.89023 0 11.8309V13.6496C0 15.5902 0.239062 17.5355 0.239062 17.5355C0.239062 17.5355 0.473437 19.1902 1.19062 19.9168C2.10469 20.873 3.30469 20.8402 3.83906 20.9434C5.76094 21.1262 12 21.1824 12 21.1824C12 21.1824 17.0438 21.173 20.4 20.934C20.8688 20.8777 21.8906 20.873 22.8047 19.9168C23.5266 19.1902 23.7609 17.5355 23.7609 17.5355C23.7609 17.5355 24 15.5949 24 13.6496V11.8309C24 9.89023 23.7609 7.94492 23.7609 7.94492ZM9.52031 15.8574V9.11211L16.0031 12.4965L9.52031 15.8574Z" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMedia;
