import { useTranslation, Trans } from 'react-i18next';
import SharingImg from '../../assets/images/Sharing.svg';

const SharingSection = () => {
  const { t } = useTranslation();
  return (
    <section id="sharing">
      <h2 className="text-2xl font-bold text-gray-900">{t('share_title')}</h2>
      <div className="my-4 text-base text-gray-500">
        <Trans i18nKey="sharing_center_instruction_1" />
        <p className="mt-6">{t('sharing_center_instruction_2')}</p>
      </div>
      <img src={SharingImg} alt="sharing center" />
    </section>
  );
};

export default SharingSection;
