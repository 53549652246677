import { useTranslation, Trans } from 'react-i18next';
import EmptyTableImg from '../../assets/images/EmptyTable.svg';
import ListImportImg from '../../assets/images/ListImport.svg';

const ListImportSection = () => {
  const { t } = useTranslation();
  const sampleExelGoogleFileLink =
    'https://docs.google.com/spreadsheets/d/1K1jG5iT3Z-EGGSktqV0zNpNweW65Tpv_mPanMUsTakM/edit?usp=sharing';

  return (
    <section id="list-import">
      <h2 className="text-2xl font-bold text-gray-900">
        {t('all_list_import')}
      </h2>
      <div className="my-6 text-base text-gray-500">
        <p>
          <Trans
            i18nKey="list_import_info"
            components={{
              1: (
                <button
                  className="font-bold underline hover:text-gray-900"
                  onClick={() => {
                    window.open(sampleExelGoogleFileLink);
                  }}
                />
              )
            }}
          />
        </p>
        <ul className="mt-4 mb-6">
          <li>
            <Trans i18nKey="list_import_instruction_1" />
            <img src={EmptyTableImg} alt="table" className="mt-4 mb-8" />
          </li>
          <li>{t('list_import_instruction_2')}</li>
          <Trans i18nKey="list_import_instruction_3" />
        </ul>
        <img src={ListImportImg} alt="list import" />
      </div>
    </section>
  );
};

export default ListImportSection;
