import DownloadButton from '../../../components/DownloadButton';
import LangSelector from '../../../components/LangSelector';
import LogoGrey from '../../../assets/icons/LogoGray.svg';
import GooglePlay from '../../../assets/icons/google-play-icon.svg';
import AppleIcon from '../../../assets/icons/apple-icon.svg';
import HuaweiIcon from '../../../assets/icons/huawei-icon.svg';
import SocialMedia from './SocialMedia';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  document.body.setAttribute('style', 'background: #F8FAFC');

  const downloadBtnData = [
    {
      id: '1',
      icon: GooglePlay,
      paragraph: t('gp_badge_header'),
      span: 'Google Play',
      link: 'https://play.google.com/store/apps/details?id=com.l&referrer=utm_source%3Dlistonic.com%26utm_medium%3Dreferral%26utm_term%3Dno-keyword%26utm_content%3Dslider%26utm_campaign%3Dslider'
    },
    {
      id: '2',
      icon: AppleIcon,
      paragraph: t('appstore_badge_header'),
      span: 'AppStore',
      link: 'https://apps.apple.com/us/app/listonic-grocery-shopping-list/id331302745'
    },
    {
      id: '3',
      icon: HuaweiIcon,
      paragraph: t('appgallery_badge_header'),
      span: 'AppGallery',
      link: 'https://appgallery.huawei.com/#/app/C103474259'
    }
  ];
  return (
    <div className="px-5 pt-16 sm:px-10 md:px-20 bg-gray-50">
      <div className="container mx-auto">
        <div className="flex flex-col items-center md:pb-4">
          <img
            src={LogoGrey}
            className="h-6 sm:ml-2 w-34"
            alt="Listonic Logo"
          />
          <div className="flex flex-col mb-12 mt-9 sm:flex-row ">
            {downloadBtnData.map((data) => (
              <DownloadButton
                key={data.id}
                id={data.id}
                icon={data.icon}
                paragraph={data.paragraph}
                span={data.span}
                link={data.link}
              />
            ))}
          </div>
        </div>
        <hr className="border-gray-200 sm:mx-auto" />
        {/* Desktop */}
        <div className="items-center justify-between hidden py-12 md:flex">
          <SocialMedia />
          <span className="block mx-8 text-sm text-center text-gray-500">
            <a
              href="https://listonic.com/privacy/"
              className="font-medium hover:text-primary-900 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href="https://listonic.com/terms-of-service/"
              className="font-medium hover:text-primary-900 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of service
            </a>{' '}
            | © 2006-2022 Listonic. All rights reserved.
          </span>
          <LangSelector />
        </div>
        {/* Mobile and tablet */}
        <div className="flex flex-col items-center justify-between pt-12 pb-12 md:hidden">
          <LangSelector />
          <div className="mt-8 mb-6 mr-3 ">
            <SocialMedia />
          </div>
          <span className="hidden text-sm text-center text-gray-500 sm:block">
            <a
              href="https://listonic.com/privacy/"
              className="font-medium hover:text-primary-900 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href="https://listonic.com/terms-of-service/"
              className="font-medium hover:text-primary-900 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of service
            </a>{' '}
            © 2006-2022 Listonic. All rights reserved.
          </span>
          <span className="block text-sm text-center text-gray-500 sm:hidden">
            <div className="flex justify-around mb-2">
              <span>
                <a
                  href="https://listonic.com/privacy/"
                  className="font-medium hover:text-primary-900 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
              <span>
                <a
                  href="https://listonic.com/terms-of-service/"
                  className="font-medium hover:text-primary-900 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of service
                </a>
              </span>
            </div>
            <span className="block">
              © 2006-2022 Listonic. All rights reserved.
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
