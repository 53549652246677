import classnames from 'classnames';
import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';

interface InputType {
  id: string;
  label?: string;
  placeholder?: string;
  textColor: string;
  textPosition?: string;
  type: string;
  error?: boolean;
  errorMessage?: string;
  width?: string;
  height: string;
  size?: string;
  fontWeight?: string;
  icon?: string | JSX.Element;
  value?: string | number;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onIconClick?: () => void | undefined;
  iconPosition?: string;
  tooltip?: boolean;
  tooltip_h1?: string;
  tooltip_p?: string;
  maxLength?: number;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}

const Input = ({
  id,
  type,
  label,
  placeholder,
  textColor,
  textPosition,
  error,
  errorMessage,
  width,
  height,
  size,
  fontWeight,
  icon,
  value,
  disabled,
  onChange,
  onIconClick,
  iconPosition,
  tooltip,
  tooltip_h1,
  tooltip_p,
  maxLength,
  onKeyPress
}: InputType) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div className={`relative flex flex-col ${width}`}>
      <label htmlFor={id} className="text-sm font-medium text-gray-900">
        {label}
      </label>
      {icon && (
        <div
          className={`${iconPosition} cursor-pointer`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={onIconClick}
        >
          {typeof icon === 'string' ? (
            <img src={icon} alt="" />
          ) : (
            <div>{icon}</div>
          )}
          {showTooltip && tooltip && (
            <span
              className={`border absolute -top-[38px] right-8 h-max w-52 sm:w-64 bg-white rounded-lg text-black text-sm before:content-[''] before:absolute before:top-12  before:-right-[5px] before:-translate-y-1/2 before:rotate-45 before:w-2.5 before:h-2.5 before:bg-white  before:border before:border-t before:border-b-white before:border-r before:border-l-white`}
            >
              <div className="divide-y">
                <h2 className="px-3 py-2 text-sm font-semibold text-gray-900 rounded-lg bg-gray-50">
                  {tooltip_h1}
                </h2>
                <p className="text-500 text-gray-500 py-1.5 px-3">
                  {tooltip_p}
                </p>
              </div>
            </span>
          )}
        </div>
      )}
      <div
        className={`${label && 'mt-2'} ${height} ${
          error ? 'bg-red-50' : 'bg-gray-50'
        } border ${error ? 'border-red-500' : 'border-gray-300'} ${
          error ? 'text-red-700' : textColor
        } text-sm rounded-lg ${
          error
            ? 'focus-within:border-red-500'
            : 'focus-within:border-primary-600'
        } focus:ring-0 placeholder:text-gray-500`}
      >
        <input
          id={id}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          type={type}
          maxLength={maxLength}
          className={classnames(
            `truncate ${textPosition} ${fontWeight} ${
              icon ? 'w-[90%]' : 'w-full'
            } h-full rounded-lg border-none focus:ring-0 focus:border-transparent bg-transparent`,
            {
              'text-xs': size === 'xs',
              'text-sm': size === 'sm',
              'text-base': size === 'base',
              'text-xl': size === 'lg'
            }
          )}
          onKeyPress={onKeyPress}
        ></input>
      </div>
      {error && <p className="text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};

export default Input;
