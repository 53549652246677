import React, { useEffect } from 'react';
import { useAuthContext } from '../../store/AuthContext';
import Footer from './Footer';
import Navbar from './Navbar';

interface LayoutType {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutType) => {
  const { username, getUsername } = useAuthContext();

  useEffect(() => {
    getUsername();
  }, [username]);

  return (
    <div className="bg-white">
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
