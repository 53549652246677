import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { useAppContext } from '../../store/AppContext';
import Layout from '../Layout';
import CreateNewListModal from './CreateNewListModal';
import Pagination from '../../components/Pagination';
import ListLine from './ListLine';
import DeleteListModal from './DeleteListModal';
import Spinner from '../../components/Spinner';
import ProgressPage from '../../components/ProgressPage';

interface currentItemsMyLists {
  items: {
    amount: string;
    description: string;
    name: string;
    unit: string;
  }[];
  linkId: number;
  name: string;
  source: string;
  url: string;
  isWorkInProgress: boolean;
  utmParams: { campaign: string; source: string; medium: string };
}

const MyLists = () => {
  const itemsPerPage = 5;

  const {
    myLists,
    setMyLists,
    loading,
    getMyLists,
    setLoading,
    deleteList,
    activeListId
  } = useAppContext();
  const accessToken = localStorage.getItem('accessToken');
  const { t } = useTranslation();

  const { showCreateNewListModal, setShowCreateNewListModal } = useAppContext();

  useDisableBodyScroll(showCreateNewListModal);

  const [itemOffset, setItemOffset] = useState<number>(0);
  const [currentItemsMyLists, setCurrentItemsMyLists] = useState<
    currentItemsMyLists[]
  >([]);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const list = await getMyLists();
      if (list) {
        setMyLists(list);
        setCurrentItemsMyLists(list.slice(itemOffset, 5));
        setPageCount(Math.ceil(list.length / itemsPerPage));
      }
      setLoading(false);
    })();
  }, [accessToken]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    myLists && setCurrentItemsMyLists(myLists.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage]);

  const deleteHandler = async () => {
    await deleteList(activeListId);

    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <ProgressPage />
      ) : (
        <Layout>
          <DeleteListModal deleteHandler={deleteHandler} />
          {showCreateNewListModal && <CreateNewListModal />}
          <div className="container flex flex-col items-center justify-center mx-auto">
            {myLists.length === 0 && !loading ? (
              <>
                <h1 className="mt-32 text-5xl font-bold sm:mt-56">
                  {t('main_view_hello')}
                </h1>
                <p className="mt-4 text-xl font-normal text-center text-custom-gray-500 w-80 sm:w-82">
                  {t('main_view_empty_text')}
                </p>
              </>
            ) : (
              <div className="container flex flex-col items-center justify-center mx-auto">
                <h1 className="mt-32 text-5xl font-bold sm:mt-40">
                  {t('all_mylists')}
                </h1>
                <p className="mt-6 text-xl font-normal text-center text-custom-gray-500">
                  {t('my_lists_subheader')}{' '}
                </p>
                <div className="flex flex-col items-center w-full mt-9 h-620px lg:w-7/12">
                  {loading && <Spinner />}
                  <div>
                    {!loading &&
                      currentItemsMyLists.map((item) => (
                        <ListLine
                          key={item.linkId}
                          id={item.linkId}
                          title={item.name}
                          itemNumber={item.items.length}
                          url={item.url}
                          isWorkInProgress={item.isWorkInProgress}
                        />
                      ))}
                  </div>
                  {!loading && myLists.length > 5 && (
                    <div className="mt-8">
                      <Pagination
                        pageCount={pageCount}
                        itemsPerPage={itemsPerPage}
                        listOfElements={myLists}
                        setItemOffset={setItemOffset}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!loading && (
              <div className="mt-12 mb-16 sm:mb-16">
                <Button
                  type="button"
                  size="base"
                  bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
                  textColor="text-white"
                  fontWeight="font-medium"
                  px="px-6"
                  height="h-12"
                  borderRadius="rounded-lg"
                  onClickHandler={() => setShowCreateNewListModal(true)}
                >
                  {myLists.length === 0 && !loading
                    ? t('first_list_btn')
                    : t('newlist_btn')}
                </Button>
              </div>
            )}
          </div>
        </Layout>
      )}
    </>
  );
};

export default MyLists;
