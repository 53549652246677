import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import { useAuthContext } from '../../../store/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const { t } = useTranslation();
  const {
    setShowForgotPasswordModal,
    logIn,
    authErrorLogin,
    rememberMe,
    setRememberMe
  } = useAuthContext();

  const checkboxHandler = () => {
    setRememberMe(!rememberMe);
  };

  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().required(t('error_message_field_is_required')),
      password: Yup.string()
        .min(6, t('error_message_wrong_password'))
        .required(t('error_message_field_is_required'))
    }),

    onSubmit: () => {
      let data = {
        username: values.email,
        password: values.password
      };
      logIn(data);
    }
  });

  return (
    <>
      <div className="grid gap-6 sm:grid-cols-2">
        <Input
          id="email"
          type="text"
          textColor="text-gray-900"
          label={t('email')}
          placeholder="name@company.com"
          size="sm"
          value={values.email}
          onChange={handleChange}
          height="h-10"
          error={!!errors.email || authErrorLogin}
          errorMessage={errors.email}
        />
        <Input
          id="password"
          type="password"
          textColor="text-gray-900"
          label={t('password')}
          placeholder="••••••••"
          size="sm"
          value={values.password}
          onChange={handleChange}
          height="h-10"
          error={!!errors.password || authErrorLogin}
          errorMessage={errors.password}
        />
        {authErrorLogin && (
          <p className="-mt-5 text-sm text-red-600">
            {t('error_message_login')}
          </p>
        )}
      </div>

      <div className="flex justify-between w-full mt-2">
        <Checkbox
          id="remember-me-checkbox"
          itemsPosition="items-center"
          label={t('login_remember_me')}
          checked={rememberMe}
          onClickHandler={checkboxHandler}
          height="h-8"
        />
        <button
          className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
          onClick={() => setShowForgotPasswordModal(true)}
        >
          {t('login_forgot_password')}
        </button>
      </div>
      <div className="mt-6">
        <Button
          type="submit"
          size="base"
          bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
          textColor="text-white"
          fontWeight="font-medium"
          width="w-full"
          height="h-12"
          borderRadius="rounded-lg"
          onClickHandler={handleSubmit}
        >
          {t('login_btn')}
        </Button>
      </div>
    </>
  );
};

export default LoginForm;
