import classnames from 'classnames';
import { ChangeEventHandler } from 'react';

interface TextAreaType {
  id: string;
  label: string;
  width?: string;
  size?: string;
  value?: string;
  fontWeight: string;
  textColor: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
}

const TextArea = ({
  id,
  label,
  width,
  size,
  value,
  onChange,
  fontWeight,
  textColor,
  placeholder
}: TextAreaType) => {
  return (
    <div className={`${width} flex flex-col`}>
      <label htmlFor={id} className="text-sm font-medium text-gray-900">
        {label}
      </label>
      <textarea
        id={id}
        rows={6}
        value={value}
        onChange={onChange}
        className={classnames(
          `${fontWeight} ${label && 'mt-2'} bg-gray-50
          } border border-gray-300 ${textColor} text-sm rounded-lg focus:border-primary-600 focus:ring-0 placeholder:text-gray-500`,
          {
            'text-xs': size === 'xs',
            'text-sm': size === 'sm',
            'text-base': size === 'base',
            'text-xl': size === 'lg'
          }
        )}
        placeholder={placeholder}
      ></textarea>
    </div>
  );
};

export default TextArea;
