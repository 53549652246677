import Button from '../../components/Button';
import Input from '../../components/Input';
import QrIcon from '../../assets/icons/qr-code-icon.svg';
import Spinner from '../../components/Spinner';
import { useRef, useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation, Trans } from 'react-i18next';
import { useAppContext } from '../../store/AppContext';
import { useAuthContext } from '../../store/AuthContext';
import { apiApp } from '../../api/index';

interface schemaType {
  name: string;
  source: string;
  items: {
    name: string;
    amount: string;
    unit: string;
    description: string;
  }[];
  utmParams: {
    campaign: string;
    source: string;
    medium: string;
  };
}

const SharingCenter = () => {
  const { LOGGED_IN_AUTH_TOKEN } = useAuthContext();
  const { t } = useTranslation();

  const currentUrl = window.location.href;
  const linkId = currentUrl.includes('listId=')
    ? currentUrl.split('=').pop()
    : '';
  const qrRef = useRef<HTMLDivElement | null>(null);

  const { newList, tableState, utmParams, url, isWorkInProgress } =
    useAppContext();

  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [copied, setCopied] = useState(false);

  const schema: schemaType = {
    name: newList,
    source: 'Listonic',
    items: tableState,
    utmParams: utmParams
  };

  useEffect(() => {
    setLoadingData(false);
    setInProgress(isWorkInProgress);
    setSuccess(false);
  }, [newList, tableState, utmParams, url, isWorkInProgress]);

  const downloadQRCode = () => {
    if (qrRef.current !== null) {
      let canvas = qrRef.current.querySelector('canvas');
      if (canvas !== null) {
        let image = canvas.toDataURL('image/png');
        let anchor = document.createElement('a');
        anchor.href = image;
        anchor.download = `qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  };

  const copyLinkToClipboard = () => {
    setCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleClick = async () => {
    if (linkId) {
      await updateLink(linkId);
    }
  };

  const updateLink = async (id: string) => {
    try {
      setLoadingData(true);
      setErrorMessage(false);

      const results = await apiApp.put('/api/link/' + id, schema, {
        headers: {
          Authorization: LOGGED_IN_AUTH_TOKEN
        }
      });
      if (results.status === 200) {
        setSuccess(true);
        setInProgress(false);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      }
      setLoadingData(false);
      return results.data;
    } catch (error: any) {
      setErrorMessage(true);
      setLoadingData(false);
      return error;
    }
  };

  return (
    <div className="border rounded-lg w-full sm:w-[405px]">
      <div className="p-8">
        <div>
          <h2 className="text-2xl font-medium text-gray-900">
            {t('share_title')}
          </h2>
          <p className="mt-3 text-sm text-gray-600">
            {url ? t('share_text_send') : t('share_text_generate')}
          </p>
          {inProgress && (
            <span className="flex leading-relaxed bg-red-100 text-red-800 text-xs mt-4 mr-2 px-2.5 py-1.5 rounded dark:bg-red-200 dark:text-red-900">
              <div>
                <Trans i18nKey={'unsaved_changes'} />
              </div>
            </span>
          )}
          <div className="flex flex-row items-center gap-6 my-8">
            <Button
              type="button"
              size="base"
              bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
              textColor="text-white"
              fontWeight="font-medium"
              height="h-12"
              px="px-6"
              borderRadius="rounded-lg"
              onClickHandler={handleClick}
            >
              {url ? t('save_changes') : t('generate_link_btn')}
            </Button>
            {loadingData ? (
              <Spinner />
            ) : errorMessage ? (
              <div className="text-sm sm:max-w-[40%] max-w-[20%]">
                <span className="flex leading-relaxed bg-red-100 text-red-800 text-xs px-2.5 py-1 rounded dark:bg-red-200 dark:text-red-900">
                  {t('something_wrong')}
                </span>
              </div>
            ) : success ? (
              <span className="flex leading-relaxed bg-green-100 text-green-800 text-xs  px-2.5 py-1.5 rounded dark:bg-green-200 dark:text-green-900">
                <div className="flex items-center text-sm sm:max-w-[40%] max-w-[20%]">
                  {t('success')}
                </div>
              </span>
            ) : (
              <></>
            )}
          </div>
          <div>
            {copied && (
              <span className="absolute z-50 translate-x-[160px] translate-y-[-10px] sm:translate-x-[230px] sm:translate-y-[-10px] bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-1.5 rounded dark:bg-green-200 dark:text-green-900">
                {t('copied_link')}
              </span>
            )}

            <Input
              id="link"
              type="text"
              label={t('link_header')}
              placeholder={t('link_empty_hint')}
              textColor="text-gray-500"
              width="w-full"
              height="h-12"
              size="sm"
              fontWeight="font-medium"
              value={url}
              disabled={true}
              onIconClick={copyLinkToClipboard}
              icon={
                <div data-tooltip-target="tooltip-default">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke={copied ? '#34B524' : '#64748B'}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                    ></path>
                  </svg>
                </div>
              }
              iconPosition="absolute right-4 top-[41px] z-30"
            />
          </div>
          {url && (
            <p className="mt-3 text-sm text-gray-500">
              {t('link_share_subtitle')}
            </p>
          )}
        </div>
        <div className="flex items-center justify-between mt-6">
          <hr className="w-24 border-gray-200 sm:w-32 border-b-border" />
          <span className="text-gray-500 sm:text-sm">{t('login_or')}</span>
          <hr className="w-24 border-gray-200 sm:w-32 border-b-border" />
        </div>
        <div className="flex flex-col items-center mt-10 mb-4">
          <div className="flex flex-col items-center justify-center w-48 h-48 gap-3 mb-8 text-center border rounded-lg">
            {url !== '' ? (
              <div ref={qrRef}>
                <QRCodeCanvas
                  id="qrCode"
                  value={url}
                  size={175}
                  bgColor="white"
                  level={'H'}
                />
              </div>
            ) : (
              <>
                <img src={QrIcon} alt="qr code icon" className="w-12 h-12" />
                <p className="px-6 text-xs text-gray-600">
                  {t('qr_empty_hint')}
                </p>
              </>
            )}
          </div>
          <Button
            type="submit"
            size="sm"
            bgColor="bg-gray-900 focus:ring-gray-300 hover:bg-white focus:ring-4 hover:border hover:border-black"
            textColor="text-white hover:text-black"
            fontWeight="font-medium"
            height="h-9"
            px="px-4"
            borderRadius="rounded-lg"
            disabled={!url}
            onClickHandler={downloadQRCode}
          >
            {t('all_download_qr')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SharingCenter;
