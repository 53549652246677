import { useFormik } from 'formik';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import * as Yup from 'yup';
import TextArea from '../../components/Textarea';
import { useAppContext } from '../../store/AppContext';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useTranslation } from 'react-i18next';

const EditItemForm = () => {
  const { t } = useTranslation();
  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 100).toString();
  const { setShowEditItemForm, rowData, setRowData, tableState } =
    useAppContext();

  const modalCloseHandler = () => {
    setShowEditItemForm(false);
    setRowData({
      item: '',
      amount: '',
      unit: '',
      description: '',
      id: null
    });
  };

  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      editItem: rowData.name,
      editAmount: rowData.amount,
      editUnit: rowData.unit,
      editDescription: rowData.description
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      editItem: Yup.string().required(),
      editAmount: Yup.number().positive()
    }),
    onSubmit: () => {
      let changedValues = {
        name: values.editItem,
        amount: values.editAmount.toString(),
        unit: values.editUnit,
        description: values.editDescription,
        id: rowData.id
      };
      tableState.splice(Number(rowData.id), 1, changedValues);
      modalCloseHandler();
    }
  });

  return (
    <>
      <Modal
        marginTop={`${margin}px`}
        width="w-80 sm:w-120"
        headingText={t('edit_item_header')}
        modalClose={modalCloseHandler}
        borderWrapper
      >
        <div className="flex flex-col justify-center gap-3 px-6 py-6">
          <Input
            id="editItem"
            type="text"
            label={t('list_item')}
            textColor="text-gray-900"
            width="w-full"
            height="h-12"
            size="text-base"
            fontWeight="font-medium"
            error={!!errors.editItem}
            errorMessage={t('error_message_field_is_required')}
            value={values.editItem}
            onChange={handleChange}
          />
          <div className="flex justify-between">
            <Input
              id="editAmount"
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              label={t('list_amount')}
              textColor="text-gray-900"
              width="w-32 sm:w-8/12"
              height="h-12"
              size="text-base"
              fontWeight="font-medium"
              error={!!errors.editAmount}
              errorMessage="Wrong amount"
              value={values.editAmount}
              onChange={handleChange}
            />
            <Input
              id="editUnit"
              type="text"
              label={t('list_unit')}
              textColor="text-gray-900"
              width="w-32 sm:w-36"
              height="h-12"
              size="text-base"
              fontWeight="font-medium"
              value={values.editUnit}
              onChange={handleChange}
            />
          </div>
          <TextArea
            id="editDescription"
            label={t('list_description')}
            textColor="text-gray-900"
            width="w-full"
            size="text-base"
            fontWeight="font-medium"
            value={values.editDescription}
            onChange={handleChange}
            placeholder={t('note_hint')}
          />
        </div>
        <div className="flex justify-start gap-3 py-6 pl-6 border-t">
          <Button
            type="submit"
            size="sm"
            bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
            textColor="text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            borderRadius="rounded-lg"
            onClickHandler={handleSubmit}
          >
            {t('save_close_btn')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditItemForm;
