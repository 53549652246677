import Table from './Table';
import Layout from '../Layout';
import { useAppContext } from '../../store/AppContext';
import EditItemForm from './EditItemForm';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import NewItemForm from './NewItemForm';
import SettingsForm from './SettingsForm';
import UploadFileForm from './UploadFileForm';
import CogIcon from '../../assets/icons/cog.svg';
import SharingCenter from './SharingCenter';
import InfoSlider from './InfoSlider';
import { useAuthContext } from '../../store/AuthContext';
import { useEffect, useLayoutEffect } from 'react';
import PencilIcon from '../../assets/icons/pencil.svg';
import usePrompt from '../../hooks/usePrompt';
import { useTranslation } from 'react-i18next';
import { apiApp } from '../../api/index';
import ProgressPage from '../../components/ProgressPage';
import DeleteListModal from '../MyLists/DeleteListModal';
import { useNavigate } from 'react-router-dom';

interface ListSchemaType {
  linkId?: number;
  name: string;
  url: string;
  source: string;
  items: {
    id: number;
    name: string;
    amount: string;
    unit: string;
    description: string;
  }[];
  utmParams: {
    campaign: string;
    source: string;
    medium: string;
  };
}

const ListCreation = () => {
  const {
    newList,
    showEditItemForm,
    showNewItemForm,
    showUploadFileForm,
    showSettingsForm,
    setShowSettingsForm,
    setUtmParams,
    setUrl,
    setTableState,
    setNewList,
    updateList,
    tableState,
    utmParams,
    rowData,
    setIsWorkInProgress,
    savingInProgress,
    setSavingInProgress,
    loading,
    setLoading,
    deleteList
  } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { LOGGED_IN_AUTH_TOKEN } = useAuthContext();
  useDisableBodyScroll(showEditItemForm);
  useDisableBodyScroll(showNewItemForm);
  useDisableBodyScroll(showSettingsForm);
  useDisableBodyScroll(showUploadFileForm);

  const currentUrl = window.location.href;
  const linkId = currentUrl.includes('listId=')
    ? currentUrl.split('=').pop()
    : '';

  usePrompt(t('leaving_page'), savingInProgress);

  useLayoutEffect(() => {
    setSavingInProgress(true);
  }, []);

  useEffect(() => {
    if (linkId?.length !== 0) {
      (async () => {
        const results = await getCurrentList(linkId);
        const resultsWithId = await addId(results);
        await setFirstRenderStates(resultsWithId);
      })();
    } else {
      setUtmParams({
        campaign: '',
        source: '',
        medium: ''
      });
      setUrl('');
      setTableState([]);
    }
  }, []);

  const getCurrentList = async (id: string | undefined) => {
    setLoading(true);
    try {
      let results = await apiApp.get('/api/link/id/' + id, {
        headers: {
          Authorization: LOGGED_IN_AUTH_TOKEN
        }
      });
      setIsWorkInProgress(results.data.isWorkInProgress);
      setLoading(false);
      return results.data;
    } catch (error: any) {
      setLoading(false);
      return error;
    }
  };

  const addId = async (results: ListSchemaType) => {
    const items: {}[] = results.items;
    const itemsWithId = items.map((obj, index) => ({
      ...obj,
      id: index
    }));
    const dataWithId: { [key: string]: any } = {
      ...results,
      items: itemsWithId
    };
    return dataWithId;
  };

  const setFirstRenderStates = async (resultsWithId: {
    [key: string]: any;
  }) => {
    setUtmParams(resultsWithId.utmParams);
    setUrl(resultsWithId.url);
    setTableState(resultsWithId.items);
    setNewList(resultsWithId.name);
    setIsWorkInProgress(resultsWithId.isWorkInProgress);
  };

  useEffect(() => {
    setSavingInProgress(true);
    const timer = setTimeout(() => {
      (async () => {
        currentUrl.includes('listId=') && (await updateList(linkId));
        setSavingInProgress(false);
      })();
    }, 1000);
    return () => clearTimeout(timer);
  }, [tableState, utmParams, newList, rowData]);

  const deleteHandler = async () => {
    await deleteList(Number(linkId));
    navigate('/my-lists');
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <ProgressPage />
      ) : (
        <Layout>
          <DeleteListModal deleteHandler={deleteHandler} />
          {showEditItemForm && <EditItemForm />}
          {showNewItemForm && <NewItemForm />}
          {showSettingsForm && <SettingsForm />}
          {showUploadFileForm && <UploadFileForm />}
          <div className="py-24">
            <div className="container flex flex-col mx-auto md:items-center lg:items-start lg:flex-row lg:gap-9">
              <div className="overflow-x-auto">
                <div className="flex items-center justify-between my-8">
                  <div
                    className="flex border border-white rounded-lg hover:bg-gray-100 focus-within:bg-gray-100 focus-within:border-gray-200"
                    style={{
                      width: 'calc(100% - 4rem)'
                    }}
                  >
                    <img
                      src={PencilIcon}
                      alt="pencil icon"
                      className="w-5 ml-2"
                    />
                    <input
                      type="text"
                      value={newList}
                      className="w-full text-4xl text-gray-900 truncate bg-transparent border-none rounded-lg focus:ring-0 focus:border-transparent"
                      onChange={(e) => {
                        setNewList(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="p-3 border rounded-full min-w-max hover:bg-gray-200"
                    onClick={() => setShowSettingsForm(true)}
                  >
                    <img src={CogIcon} alt="Cog icon" />
                  </button>
                </div>

                <Table />
              </div>
              <div className="self-center mt-8">
                <InfoSlider />
                <SharingCenter />
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default ListCreation;
