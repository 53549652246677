import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { config as AxiosConfig } from './apiRoutes';
import { AUTH_TOKEN } from '../store/AuthContext';
import { apiApp, apiAuth } from '.';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem('accessToken');
  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const originalConfig = error.config;

  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      const storedToken = localStorage.getItem('refreshToken');

      let schema = 'refresh_token=' + storedToken;

      try {
        await axios
          .post(
            `${AxiosConfig.BASE_API.API_AUTH_ENDPOINT}${AxiosConfig.apiRoutes.LOGIN_EXTENDED_REFRESH_TOKEN}`,
            schema,
            {
              headers: {
                ClientAuthorization: AUTH_TOKEN
              }
            }
          )
          .then((res: any) => {
            localStorage.setItem('refreshToken', res.data.refresh_token);
            localStorage.setItem('accessToken', res.data.access_token);
            if (
              originalConfig.baseURL === process.env.REACT_APP_BUTTON_POC_URL
            ) {
              return apiApp(originalConfig);
            } else if (
              originalConfig.baseURL === process.env.REACT_APP_AUTH_ENDPOINT
            ) {
              return apiAuth(originalConfig);
            }
          });
      } catch (_error: any) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
