import axios from 'axios';
import { createContext, useCallback, useContext, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { RowType, UtmParamsTypes } from './utils';
import { apiApp } from '../api/index';

interface ListSchemaType {
  name: string;
  source: string;
  items: {
    name: string;
    amount: string;
    unit: string;
    description: string;
  }[];
  utmParams: {
    campaign: string;
    source: string;
    medium: string;
  };
}

export type AppContextType = {
  showEditItemForm: boolean;
  setShowEditItemForm: (value: boolean) => void;
  showNewItemForm: boolean;
  setShowNewItemForm: (value: boolean) => void;
  showSettingsForm: boolean;
  setShowSettingsForm: (value: boolean) => void;
  showUploadFileForm: boolean;
  setShowUploadFileForm: (value: boolean) => void;
  tableState: {
    name: string;
    amount: string;
    unit: string;
    description: string;
    id: number | null;
  }[];
  setTableState: (value: any) => void;
  rowData: RowType;
  setRowData: (value: any) => void;
  showCreateNewListModal: boolean;
  setShowCreateNewListModal: (value: boolean) => void;
  newList: string;
  setNewList: (value: string) => void;
  getMyLists: () => Promise<any>;
  myLists: {
    items: {
      amount: string;
      description: string;
      name: string;
      unit: string;
    }[];
    linkId: number;
    name: string;
    source: string;
    url: string;
    utmParams: UtmParamsTypes;
    isWorkInProgress: boolean;
  }[];
  setMyLists: (value: any) => void;
  utmParams: UtmParamsTypes;
  setUtmParams: (value: any) => void;
  url: string;
  setUrl: (value: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  showDeleteListModal: boolean;
  setShowDeleteListModal: (value: boolean) => void;
  activeListId: number;
  setActiveListId: (value: number) => void;
  updateList: (value: string | undefined) => Promise<any>;
  isWorkInProgress: boolean;
  setIsWorkInProgress: (value: boolean) => void;
  savingInProgress: boolean;
  setSavingInProgress: (value: boolean) => void;
  deleteList: (value: number) => Promise<any>;
};

export const AppContext = createContext<AppContextType>({
  showEditItemForm: false,
  setShowEditItemForm: () => console.warn('no app provider'),
  showNewItemForm: false,
  setShowNewItemForm: () => console.warn('no app provider'),
  showSettingsForm: false,
  setShowSettingsForm: () => console.warn('no app provider'),
  showUploadFileForm: false,
  setShowUploadFileForm: () => console.warn('no app provider'),
  tableState: [],
  setTableState: () => console.warn('no app provider'),
  rowData: {
    name: '',
    amount: '',
    unit: '',
    description: '',
    id: null
  },
  setRowData: () => console.warn('no app provider'),
  showCreateNewListModal: false,
  setShowCreateNewListModal: () => console.warn('no app provider'),
  newList: '',
  setNewList: () => console.warn('no app provider'),
  getMyLists: async () => console.warn('no app provider'),
  myLists: [],
  setMyLists: () => console.warn('no app provider'),
  utmParams: {
    campaign: '',
    source: '',
    medium: ''
  },
  setUtmParams: () => console.warn('no app provider'),
  url: '',
  setUrl: () => console.warn('no app provider'),
  loading: false,
  setLoading: () => console.warn('no app provider'),
  showDeleteListModal: false,
  setShowDeleteListModal: () => console.warn('no app provider'),
  activeListId: 0,
  setActiveListId: () => console.warn('no app provider'),
  updateList: async () => console.warn('no app provider'),
  isWorkInProgress: false,
  setIsWorkInProgress: () => console.warn('no app provider'),
  savingInProgress: false,
  setSavingInProgress: () => console.warn('no app provider'),
  deleteList: async () => console.warn('no app provider')
});

export const AppContextProvider = (props: any) => {
  const { LOGGED_IN_AUTH_TOKEN } = useAuthContext();
  const [showEditItemForm, setShowEditItemForm] = useState<boolean>(false);
  const [showNewItemForm, setShowNewItemForm] = useState<boolean>(false);
  const [showSettingsForm, setShowSettingsForm] = useState<boolean>(false);
  const [showUploadFileForm, setShowUploadFileForm] = useState<boolean>(false);
  const [tableState, setTableState] = useState([]);
  const [rowData, setRowData] = useState({
    name: '',
    amount: '',
    unit: '',
    description: '',
    id: null
  });
  const [showCreateNewListModal, setShowCreateNewListModal] =
    useState<boolean>(false);
  const [showDeleteListModal, setShowDeleteListModal] =
    useState<boolean>(false);
  const [newList, setNewList] = useState<string>('');
  const [myLists, setMyLists] = useState([]);
  const [utmParams, setUtmParams] = useState({
    campaign: '',
    source: '',
    medium: ''
  });
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [activeListId, setActiveListId] = useState<number>(0);
  const [isWorkInProgress, setIsWorkInProgress] = useState<boolean>(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const TemporaryList: ListSchemaType = {
    name: newList,
    source: 'Listonic',
    items: tableState,
    utmParams: utmParams
  };

  const getMyLists = async () => {
    try {
      let results = await apiApp.get('/api/link', {
        headers: {
          Authorization: LOGGED_IN_AUTH_TOKEN
        }
      });
      return results.data;
    } catch (error: any) {
      return error;
    }
  };

  const updateTemporaryList = useCallback(
    async (linkId: any) => {
      const controller = new AbortController();
      try {
        let results = await apiApp.put(
          '/api/link/temp?id=' + linkId,
          TemporaryList,
          {
            headers: {
              Authorization: LOGGED_IN_AUTH_TOKEN
            }
          }
        );
        setIsWorkInProgress(results.data.isWorkInProgress);
        return results.data;
      } catch (error: any) {
        if (axios.isCancel(error)) {
          setLoading(false);
          setSavingInProgress(false);
        } else {
          setLoading(false);
          setSavingInProgress(false);
          return error;
        }
      }

      controller.abort();
    },
    [TemporaryList]
  );

  const deleteList = async (linkId: number) => {
    try {
      await apiApp
        .delete('/api/link/' + linkId, {
          headers: {
            Authorization: LOGGED_IN_AUTH_TOKEN
          }
        })
        .then((res) => {
          return res.data;
        });
    } catch (error: any) {
      return error;
    }
  };

  return (
    <AppContext.Provider
      value={{
        showEditItemForm,
        setShowEditItemForm,
        showNewItemForm,
        setShowNewItemForm,
        showSettingsForm,
        setShowSettingsForm,
        showUploadFileForm,
        setShowUploadFileForm,
        tableState,
        setTableState,
        rowData,
        setRowData,
        showCreateNewListModal,
        setShowCreateNewListModal,
        newList,
        setNewList,
        getMyLists,
        myLists,
        setMyLists,
        utmParams,
        setUtmParams,
        url,
        setUrl,
        loading,
        setLoading,
        showDeleteListModal,
        setShowDeleteListModal,
        activeListId,
        setActiveListId,
        updateList: updateTemporaryList,
        isWorkInProgress,
        setIsWorkInProgress,
        savingInProgress,
        setSavingInProgress,
        deleteList
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
