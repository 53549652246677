import classNames from 'classnames';

interface dropdownData {
  id: string;
  text: string;
  textColor?: string;
  icon: string | JSX.Element;
  onClick: (() => void) | ((e: Event) => void);
}

interface DropdownDataType {
  dropdownData: dropdownData[];
}

const Dropdown = ({ dropdownData }: DropdownDataType) => {
  return (
    <div className="block p-2 bg-white divide-gray-100 rounded shadow w-max">
      {dropdownData.map((data: any) => (
        <div
          key={data.id}
          id={data.id}
          className={classNames(
            `flex px-2 py-3 text-sm ${
              data.textColor ? data.textColor : 'text-gray-500'
            } rounded cursor-pointer hover:bg-gray-50`
          )}
          onClick={data.onClick}
        >
          {typeof data.icon === 'string' ? (
            <img src={data.icon} alt="" className="mr-2" />
          ) : (
            <div className="mr-2">{data.icon}</div>
          )}
          {data.text}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
