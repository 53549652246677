import { useState } from 'react';
import Loading_1 from '../../assets/images/loading_1.svg';
import Loading_2 from '../../assets/images/loading_2.svg';
import Loading_3 from '../../assets/images/loading_3.svg';
import Loading_4 from '../../assets/images/loading_4.svg';
import Loading_5 from '../../assets/images/loading_5.svg';
import Loading_6 from '../../assets/images/loading_6.svg';
import Loading_7 from '../../assets/images/loading_7.svg';
import Loading_8 from '../../assets/images/loading_8.svg';
import Loading_9 from '../../assets/images/loading_9.svg';
import Loading_10 from '../../assets/images/loading_10.svg';
import Loading_11 from '../../assets/images/loading_11.svg';

const ProgressPage = () => {
  const imagesArray: string[] = [
    Loading_1,
    Loading_2,
    Loading_3,
    Loading_4,
    Loading_5,
    Loading_6,
    Loading_7,
    Loading_8,
    Loading_9,
    Loading_10,
    Loading_11
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(
    Math.floor(Math.random() * imagesArray.length)
  );

  return (
    <div className="absolute z-10 w-full h-full bg-custom-secondar-25">
      <div className="flex justify-center">
        <div className="flex flex-col items-center w-64 mt-48 rounded-lg h-96">
          <img
            src={imagesArray[currentImageIndex]}
            alt=""
            className="w-48 h-48 mt-4"
          />
          <div className="relative h-1 mt-12 bg-gray-200 rounded-lg w-52">
            <div className="absolute w-32 h-full rounded-lg bg-primary-600 animate-loading"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressPage;
