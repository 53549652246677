import { useEffect } from 'react';

const useRedirectToHttps = () => {
  const { pathname, protocol, host } = window.location;
  useEffect(() => {
    if (protocol !== 'https:' && host !== 'localhost:3000') {
      window.location.replace(process.env.REACT_APP_WEBSITE_URL + pathname);
    }
  });
};

export default useRedirectToHttps;
