export const GlobIcon = ({ stroke }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.54583 9.91081H4.16667C4.60869 9.91081 5.03262 10.0864 5.34518 10.399C5.65774 10.7115 5.83333 11.1354 5.83333 11.5775V12.4108C5.83333 12.8528 6.00893 13.2768 6.32149 13.5893C6.63405 13.9019 7.05797 14.0775 7.5 14.0775C7.94203 14.0775 8.36595 14.2531 8.67851 14.5656C8.99107 14.8782 9.16667 15.3021 9.16667 15.7441V18.1983M6.66667 4.02331V5.32747C6.66667 5.88001 6.88616 6.40991 7.27686 6.80061C7.66756 7.19131 8.19747 7.41081 8.75 7.41081H9.16667C9.60869 7.41081 10.0326 7.5864 10.3452 7.89896C10.6577 8.21152 10.8333 8.63545 10.8333 9.07747C10.8333 9.5195 11.0089 9.94342 11.3215 10.256C11.634 10.5685 12.058 10.7441 12.5 10.7441C12.942 10.7441 13.366 10.5685 13.6785 10.256C13.9911 9.94342 14.1667 9.5195 14.1667 9.07747C14.1667 8.63545 14.3423 8.21152 14.6548 7.89896C14.9674 7.5864 15.3913 7.41081 15.8333 7.41081H16.72M12.5 17.8175V15.7441C12.5 15.3021 12.6756 14.8782 12.9882 14.5656C13.3007 14.2531 13.7246 14.0775 14.1667 14.0775H16.72M17.5 10.7441C17.5 11.7291 17.306 12.7043 16.9291 13.6143C16.5522 14.5242 15.9997 15.351 15.3033 16.0474C14.6069 16.7439 13.7801 17.2963 12.8701 17.6732C11.9602 18.0501 10.9849 18.2441 10 18.2441C9.01509 18.2441 8.03982 18.0501 7.12987 17.6732C6.21993 17.2963 5.39314 16.7439 4.6967 16.0474C4.00026 15.351 3.44781 14.5242 3.0709 13.6143C2.69399 12.7043 2.5 11.7291 2.5 10.7441C2.5 8.75502 3.29018 6.84736 4.6967 5.44084C6.10322 4.03432 8.01088 3.24414 10 3.24414C11.9891 3.24414 13.8968 4.03432 15.3033 5.44084C16.7098 6.84736 17.5 8.75502 17.5 10.7441Z"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
