import { UserIcon } from '../../../assets/icons/user';
import LogoutIcon from '../../../assets/icons/logout.svg';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../store/AuthContext';

interface DropdownType {
  children?: React.ReactNode;
}

const Dropdown = ({ children }: DropdownType) => {
  const { logOut, username } = useAuthContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="block bg-white divide-y divide-gray-100 rounded shadow">
        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
          <div className="flex items-center gap-2 font-medium truncate">
            <UserIcon strokeColor="#0F172A" />
            {username}
          </div>
        </div>
        {children}

        <div
          className="flex px-4 py-3 text-sm text-red-500 cursor-pointer hover:bg-gray-100"
          onClick={logOut}
        >
          <img src={LogoutIcon} alt="User Icon" className="mr-4" />
          {t('all_logout')}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
