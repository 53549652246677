import { ChangeEventHandler } from 'react';

interface CheckboxType {
  id: string;
  label?: string | React.ReactElement;
  checked: boolean;
  onClickHandler: ChangeEventHandler<HTMLInputElement>;
  itemsPosition: string;
  height?: string;
  error?: boolean;
  errorMessage?: string;
}

const Checkbox = ({
  id,
  label,
  checked,
  onClickHandler,
  itemsPosition,
  height,
  error,
  errorMessage
}: CheckboxType) => {
  return (
    <div>
      <div className={`flex ${itemsPosition} ${height}`}>
        <input
          id={id}
          type="checkbox"
          onChange={onClickHandler}
          checked={checked}
          className={`w-4 h-4 text-primary-600 bg-gray-50 rounded ${
            error ? 'border-red-600' : 'border-gray-300'
          }  focus:ring-primary-300 focus:ring-3 cursor-pointer`}
        />
        <label htmlFor={id} className="ml-2 text-sm text-gray-600">
          {label}
        </label>
      </div>
      {error && <p className="text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};

export default Checkbox;
