import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useAppContext } from '../../store/AppContext';
import { Modal } from 'flowbite-react';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';

interface DeleteListModal {
  deleteHandler: () => void;
}

const DeleteListModal = ({ deleteHandler }: DeleteListModal) => {
  const { t } = useTranslation();
  const { showDeleteListModal, setShowDeleteListModal } = useAppContext();

  useDisableBodyScroll(showDeleteListModal);

  return (
    <Modal
      show={showDeleteListModal}
      size="md"
      popup={true}
      onClose={() => setShowDeleteListModal(false)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <div className="px-6">
            <svg
              className="w-[42px] h-[42px]"
              fill="none"
              stroke="#9CA3AF"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div className="text-gray-500">{t('delete_question')}</div>
        </div>

        <div className="flex justify-center gap-4">
          <Button
            type="button"
            size="sm"
            bgColor="bg-red-500 focus:bg-red-800 hover:bg-red-700 focus:ring-2 focus:ring-red-300"
            textColor="text-white"
            fontWeight="font-medium"
            px="px-3"
            height="h-10"
            borderRadius="rounded-lg"
            onClickHandler={deleteHandler}
          >
            {t('delete_yes')}
          </Button>
          <Button
            type="button"
            size="sm"
            bgColor="bg-white focus:ring-gray-300 hover:bg-gray-100 focus:ring-4"
            textColor="text-gray-900"
            fontWeight="font-medium"
            px="px-3"
            height="h-10"
            border="border"
            borderRadius="rounded-lg"
            onClickHandler={() => setShowDeleteListModal(false)}
          >
            {t('delete_no')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteListModal;
