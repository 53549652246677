export const FacebookLoginIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_498_54730)">
        <path
          d="M24 12.7441C24 6.11672 18.6274 0.744141 12 0.744141C5.37258 0.744141 0 6.11672 0 12.7441C0 18.7336 4.3882 23.6981 10.125 24.5984V16.2129H7.07812V12.7441H10.125V10.1004C10.125 7.09289 11.9166 5.43164 14.6576 5.43164C15.9701 5.43164 17.3438 5.66602 17.3438 5.66602V8.61914H15.8306C14.34 8.61914 13.875 9.54422 13.875 10.4941V12.7441H17.2031L16.6711 16.2129H13.875V24.5984C19.6118 23.6981 24 18.7336 24 12.7441Z"
          fill="#4898F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_498_54730">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.744141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
