import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { GlobIcon } from '../../assets/icons/globe';

interface dataOptionsType {
  value: string;
  name: string;
}

const langSelectorData = [
  {
    value: 'en',
    name: 'English (EN)'
  },
  {
    value: 'pl',
    name: 'Polish (PL)'
  }
];

const LangSelector = () => {
  const [lang, setLang] = useState(i18next.language);
  const [strokeColor, setStrokeColor] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, [lang]);

  return (
    <div
      className="flex items-center cursor-pointer"
      onMouseEnter={() => setStrokeColor(true)}
      onMouseLeave={() => setStrokeColor(false)}
    >
      <GlobIcon stroke={strokeColor ? '#111827' : '#64748B'} />
      <select
        id="lang"
        className="text-sm font-medium text-gray-500 bg-transparent border-none cursor-pointer hover:text-gray-900 focus:ring-transparent"
        onChange={(e) => setLang(e.target.value)}
        value={lang}
      >
        {langSelectorData.map((data: dataOptionsType) => (
          <option key={data.value} value={data.value}>
            {data.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LangSelector;
