import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import TextArea from '../../components/Textarea';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useAppContext } from '../../store/AppContext';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { apiApp } from '../../api/index';

interface FileErrorTypes {
  errorRows: number[];
  failed: number;
  succeeded: number;
}

interface ItemArrayType {
  name: string;
  amount: string;
  unit: string;
  description: string;
  id: number | null;
}

const sampleExelGoogleFileLink =
  'https://docs.google.com/spreadsheets/d/1K1jG5iT3Z-EGGSktqV0zNpNweW65Tpv_mPanMUsTakM/edit?usp=sharing';

const UploadFileForm = () => {
  const { t } = useTranslation();
  const { setShowUploadFileForm, setTableState } = useAppContext();
  const scrollHeight = useScrollPosition();
  let margin = (scrollHeight + 100).toString();
  const [cookies] = useCookies();
  const LOGGED_IN_AUTH_TOKEN = 'Bearer ' + cookies.accessToken;
  const [file, setFile] = useState<File | null>(null);
  const [fileErrorBoolean, setFileErrorBoolean] = useState<boolean>(false);
  const [fileErrorData, setFileErrorData] = useState<FileErrorTypes>();
  const [error400, setError400] = useState(false);

  const modalCloseHandler = () => {
    setShowUploadFileForm(false);
  };

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {
      items: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      items: Yup.string()
    }),
    onSubmit: () => {
      if (values.items !== '') {
        parseItems();
        modalCloseHandler();
      } else {
        getUploadFileData();
      }
    }
  });

  const parseItems = () => {
    setFileErrorBoolean(false);
    setError400(false);
    let separateItems = values.items.split(/\r?\n|\r|\n/g);
    let createItemRow = separateItems.map((item) =>
      item.split(',').map((value) => value.trim())
    );
    var state: any[] = [];
    if (createItemRow) {
      createItemRow.forEach((array) => {
        const numberRegex = /(?=[0-9])/;
        let item = array[0];
        let amount = array[1];
        let unit = array[2];
        let description = array[3];
        state.push({
          name: item,
          amount: numberRegex.test(amount) && +amount > 0 ? amount : '',
          unit: unit,
          description: description,
          id: state.length
        });
        setTableState(state);
      });
    }
  };

  const getUploadFileData = async () => {
    setFileErrorData({
      errorRows: [],
      failed: 0,
      succeeded: 0
    });
    setError400(false);
    setFileErrorBoolean(false);
    const formValue = new FormData();
    if (file) {
      formValue.append('file', file);
    }
    try {
      await apiApp
        .post('/api/file', formValue, {
          headers: {
            Authorization: LOGGED_IN_AUTH_TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          setFileErrorData(res.data.importResult);
          if (res.data.importResult.failed !== 0) {
            setFileErrorBoolean(true);
          } else {
            const resItems: ItemArrayType[] = res.data.items;
            const upadateId = resItems.map((obj, index) => ({
              ...obj,
              id: index
            }));
            setTableState(upadateId);
          }
        })
        .then(() => modalCloseHandler());
    } catch (error: any) {
      if (error.response.status === 400) {
        setError400(true);
      }
      return error;
    }
  };

  return (
    <>
      <Modal
        marginTop={`${margin}px`}
        width="w-80 sm:w-120"
        headingText={t('import_your_list')}
        modalClose={modalCloseHandler}
        borderWrapper
      >
        <div className="flex flex-col justify-center gap-3 px-6 py-6">
          <TextArea
            id="items"
            label={t('import_list_paste')}
            textColor="text-gray-900"
            width="w-full"
            size="text-base"
            fontWeight="font-medium"
            placeholder={`${t('list_item')}, ${t(
              'list_amount'
            ).toLocaleLowerCase()}, ${t('list_unit').toLocaleLowerCase()}, ${t(
              'list_description'
            ).toLocaleLowerCase()}...`}
            value={values.items}
            onChange={handleChange}
          />
          <p className="text-xs text-gray-500">{t('import_list_paste_info')}</p>
          <label
            className="block mt-4 text-base font-medium text-gray-900"
            htmlFor="fileValue"
          >
            {t('import_list_upload')}
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            aria-describedby="fileValue_help"
            id="fileValue"
            type="file"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => {
              if (e.currentTarget.files) {
                setFile(e.currentTarget.files[0]);
                setError400(false);
                setFileErrorBoolean(false);
              }
            }}
          />
          <p className="text-xs text-gray-500" id="fileValue_help">
            <Trans
              i18nKey="import_list_upload_help"
              components={{
                1: (
                  <button
                    className="font-bold underline hover:text-gray-900"
                    onClick={() => {
                      window.open(sampleExelGoogleFileLink);
                    }}
                  />
                )
              }}
            />
          </p>

          {!!file && !!values.items && (
            <p className="text-sm text-red-600">
              {t('error_message_upload_file')}
            </p>
          )}
          {error400 && (
            <p className="text-sm text-red-600">
              {t('error_message_file_400')}
            </p>
          )}
          {fileErrorBoolean && (
            <>
              <div
                className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Danger</span>
                <div>
                  <span className="font-bold">
                    {t('error_message_file_row_with_error')}
                  </span>
                  <ul className="mt-1.5 ml-4 text-red-700 list-disc list-inside">
                    <li>
                      {t('error_message_file_row_numbers')}{' '}
                      {fileErrorData?.failed}
                    </li>
                    <li>
                      {t('error_message_file_how_many_row_errors')}{' '}
                      {fileErrorData?.errorRows.map(
                        (item) => item.toString() + ', '
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-start gap-3 py-6 pl-6 border-t">
          <Button
            type="submit"
            size="sm"
            bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
            textColor="text-white"
            fontWeight="font-medium"
            height="h-9"
            px="px-3"
            borderRadius="rounded-lg"
            onClickHandler={handleSubmit}
            disabled={!!file && !!values.items}
          >
            {t('import_lists')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UploadFileForm;
