import { useTranslation } from 'react-i18next';
import Layout from '../Layout';
import ListEditingSection from './ListEditingSection';
import ListImportSection from './ListImportSection';
import Menu from './Menu';
import SettingsSection from './SettingsSection';
import SharingSection from './SharingSection';

const Instruction = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="pb-12 md:pb-44 pt-28 md:pt-36">
        <div className="container flex flex-col mx-auto md:flex-row md:justify-between">
          <Menu />
          <div className="flex flex-col mt-10 md:ml-16 md:mt-0">
            <h1 className="text-4xl font-bold text-gray-900">
              {t('help_title')}
            </h1>
            <p className="mt-2 text-2xl text-gray-500"> {t('help_subtitle')}</p>
            <ListEditingSection />
            <hr className="my-12 border-gray-200 md:my-16" />
            <SharingSection />
            <hr className="my-12 border-gray-200 md:my-16" />
            <SettingsSection />
            <hr className="my-12 border-gray-200 md:my-16" />
            <ListImportSection />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Instruction;
