import { useTranslation } from 'react-i18next';
import SettingsImg from '../../assets/images/Settings.svg';

const SettingsSection = () => {
  const { t } = useTranslation();
  return (
    <section id="settings">
      <h2 className="text-2xl font-bold text-gray-900">{t('all_settings')}</h2>
      <p className="text-base text-gray-500 my-4">{t('settings_info')}</p>
      <img src={SettingsImg} alt="settings" />
    </section>
  );
};

export default SettingsSection;
