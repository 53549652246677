import DotsIcon from '../../assets/icons/dots.svg';
import PencilIcon from '../../assets/icons/pencil.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import MinusIcon from '../../assets/icons/minus.svg';
import PlusGrayIcon from '../../assets/icons/plus-gray.svg';
import Button from '../../components/Button';
import { useRef, useState, useEffect } from 'react';
import Dropdown from '../../components/Dropdown';
import CloudUploadIcon from '../../assets/icons/cloud-upload.svg';
import TrashIcon from '../../assets/icons/trash.svg';
import { useAppContext } from '../../store/AppContext';
import { useTranslation, Trans } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import classnames from 'classnames';

const Table = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const {
    setShowEditItemForm,
    setShowNewItemForm,
    setShowUploadFileForm,
    tableState,
    setTableState,
    setRowData
  } = useAppContext();

  const [showDropdown, setShowDropdown] = useState(false);
  const [data, setData] = useState(tableState);

  useEffect(() => {
    setData(tableState);
  }, [tableState]);

  const dropdownData = [
    {
      id: '1',
      text: t('import_lists'),
      icon: CloudUploadIcon,
      onClick: () => {
        setShowUploadFileForm(true);
        toggleDropdownModal();
      }
    },
    {
      id: '2',
      text: t('remove_products'),
      icon: TrashIcon,
      onClick: () => {
        setTableState([]);
        toggleDropdownModal();
      }
    }
  ];

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const openEditForm = () => {
    setShowEditItemForm(true);
  };

  const openNewForm = () => {
    setShowNewItemForm(true);
  };

  const toggleDropdownModal = () => {
    setShowDropdown(!showDropdown);
  };
  useOnClickOutside(ref, handleClickOutside);

  const increaseHandler = (index: number) => {
    const newState = data.map((obj) => {
      const amountToNumber = +obj.amount;
      let newAmount = +obj.amount + 1;
      if (obj.id === index && amountToNumber < 999999) {
        return { ...obj, amount: newAmount.toString() };
      }
      return obj;
    });
    setData(newState);
    setTableState(newState);
  };

  const decreaseHandler = (index: number) => {
    const newState = data.map((obj) => {
      const amountToNumber = +obj.amount;
      let newAmount = +obj.amount - 1;
      if (obj.id === index && amountToNumber >= 2) {
        return { ...obj, amount: newAmount.toString() };
      }
      return obj;
    });
    setData(newState);
    setTableState(newState);
  };

  const updateAmountFromInputHandler = (index: number, newAmount: string) => {
    const newState = data.map((obj) => {
      if (obj.id === index) {
        return { ...obj, amount: newAmount };
      }

      return obj;
    });
    setData(newState);
    setTableState(newState);
  };

  const deleteItem = (element: any) => {
    let array = data;
    let indexOfElement = array.indexOf(element);
    if (indexOfElement !== -1) {
      array.splice(indexOfElement, 1);
      setData(array);
    }
    const newState = data.filter((item) => {
      return item !== element;
    });
    const upadateId = newState.map((obj, index) => ({
      ...obj,
      id: index
    }));
    setData(upadateId);
    setTableState(upadateId);
  };

  return (
    <div className="pl-2 overflow-x-auto">
      <div
        className="relative overflow-hidden border rounded-lg w-200"
        ref={ref}
      >
        {showDropdown && (
          <div className="absolute mt-10 right-2">
            <Dropdown dropdownData={dropdownData} />
          </div>
        )}
        <table className="text-sm text-left text-gray-500 w-200">
          <thead className="text-xs font-semibold uppercase bg-gray-50">
            <tr className="h-12">
              <th scope="col" className="w-40 px-6">
                {t('list_item')}
              </th>
              <th scope="col" className="flex justify-center w-full pr-5 mb-5">
                {t('list_amount')}
              </th>
              <th scope="col" className="w-16 px-6">
                {t('list_unit')}
              </th>
              <th scope="col" className="px-6 truncate w-81">
                {t('list_description')}
              </th>
              <th
                scope="col"
                className="py-3.5 pl-6 pr-3 flex justify-end cursor-pointer"
              >
                <button
                  onClick={toggleDropdownModal}
                  className="px-3 py-1.5 bg-transparent hover:bg-white rounded-md focus:outline-none focus:ring-4 focus:ring-white"
                >
                  <img src={DotsIcon} alt="dots icon" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length !== 0 &&
              data.map((element, index) => (
                <tr className="bg-white border-y hover:bg-gray-50" key={index}>
                  <td className="px-6 py-2 font-semibold text-gray-900 max-w-[130px] break-words">
                    {element.name}
                  </td>
                  <td className="flex items-center w-40 py-2">
                    <div className="mr-2">
                      <Button
                        type="button"
                        size="base"
                        bgColor="bg-transparent hover:bg-gray-200"
                        textColor="text-white"
                        fontWeight="font-medium"
                        px="px-2"
                        height="h-8"
                        border="border"
                        borderRadius="rounded-full"
                        onClickHandler={() => {
                          decreaseHandler(index);
                        }}
                      >
                        <img src={MinusIcon} alt="minus icon" />
                      </Button>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={element.amount}
                        className={classnames(
                          `${
                            element.amount.length < 4
                              ? 'text-center'
                              : 'text-left'
                          } flex items-center justify-center h-8 mr-2 text-sm  border border-gray-300 rounded-lg w-14 bg-gray-50`
                        )}
                        onKeyPress={(event) => {
                          if (!/[0-9,.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          updateAmountFromInputHandler(index, e.target.value);
                        }}
                      />
                    </div>

                    <Button
                      type="button"
                      size="base"
                      bgColor="bg-transparent hover:bg-gray-200"
                      textColor="text-white"
                      fontWeight="font-medium"
                      px="px-2"
                      height="h-8"
                      border="border"
                      borderRadius="rounded-full"
                      onClickHandler={() => {
                        increaseHandler(index);
                      }}
                    >
                      <img src={PlusGrayIcon} alt="plus icon" />
                    </Button>
                  </td>
                  <td
                    valign="top"
                    className="pt-3.5 pl-6 pb-2 max-w-[120px] truncate"
                  >
                    {element.unit}
                  </td>
                  <td
                    valign="top"
                    className="pt-3.5 pl-6 pb-2 truncate max-w-[150px]"
                  >
                    {element.description}
                  </td>
                  <td className="flex py-2 pl-1">
                    <Button
                      type="button"
                      size="xs"
                      bgColor="bg-transparent hover:bg-gray-200"
                      textColor="text-gray-900"
                      fontWeight="font-medium"
                      px="px-4"
                      height="h-8"
                      border="border"
                      borderRadius="rounded-lg"
                      onClickHandler={() => {
                        setRowData({ ...element, index });
                        openEditForm();
                      }}
                    >
                      <img
                        src={PencilIcon}
                        alt="pencil icon"
                        className="mr-1"
                      />
                      {t('all_edit')}
                    </Button>
                    <Button
                      type="button"
                      size="xs"
                      bgColor="bg-transparent"
                      textColor="text-gray-900"
                      fontWeight="font-medium"
                      px="px-3"
                      height="h-8"
                      onClickHandler={() => {
                        deleteItem(element);
                      }}
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {data.length === 0 && (
          <div className="flex py-4 pl-6 bg-white border-b sm:pl-0 sm:justify-center">
            <p className="text-sm text-">
              <Trans
                i18nKey="lists_empty_text"
                components={{
                  1: (
                    <button
                      className="font-bold hover:underline"
                      onClick={() => setShowUploadFileForm(true)}
                    />
                  )
                }}
              />
            </p>
          </div>
        )}

        <div className="flex my-4 ml-6 sm:ml-0 sm:justify-center">
          <Button
            type="button"
            size="sm"
            bgColor="bg-primary-600 focus:ring-primary-300 hover:bg-primary-700 focus:ring-4"
            textColor="text-white"
            fontWeight="font-medium"
            px="px-4"
            height="h-8"
            borderRadius="rounded-lg"
            onClickHandler={openNewForm}
          >
            <img src={PlusIcon} alt="plus icon" className="mr-1" />
            {t('add_item_btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Table;
